import React, { useEffect, useState } from 'react';
import { FormControl, Checkbox, FormGroup, FormControlLabel } from '@mui/material'
import './CheckboxList.css'

interface CheckboxListProps {
    listOptions: {
        id: number,
        displayName?: string
    }[],
    title?: string,
    allOption: boolean,
    selectedOptions: string[],
    setSelectedOptions(newData: string[]): void,
}

const CheckboxList = ({listOptions, title, allOption, selectedOptions, setSelectedOptions }: CheckboxListProps) => {
  const [selectedOptionsIds, setSelectedOptionsIds] = useState<string[]>(selectedOptions)

    useEffect(() => {
      // Valido la longitud para evitar que siga refrescando la seleccion.
      if(selectedOptionsIds.length != selectedOptions.length)
      setSelectedOptionsIds(selectedOptions);
    }, [ selectedOptions, setSelectedOptions])

    const handleOptionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedOptionId = event.target.value;
        let newList: string[] = [];
        if (selectedOptionId === 'all') {
          if (!selectedOptionsIds.includes('all')) {
            newList = listOptions.map(lo=>lo.id.toString());
            newList.push('all')

          }
        } else {
          if(selectedOptionsIds.findIndex(a=> a === selectedOptionId) >= 0) {
            newList = selectedOptionsIds.filter(a=>a != selectedOptionId);
          } else {
            newList = Object.assign([], selectedOptionsIds);
            newList.push(selectedOptionId);
          }
        }

        setSelectedOptionsIds(newList);
        // Valido si existe una funcion de onChange
        if(setSelectedOptions)
          setSelectedOptions(newList);
      }
    
      

    return(
        <>
          <span className='setting-attr-title' style={{ marginTop: '20px' }}>
              {title}
          </span>
          <FormControl className='setting-checkbox-list' size='small'>
              <FormGroup>
                {allOption ? 
                  <FormControlLabel
                      className='setting-checkbox-item'
                      control={<Checkbox
                          checked={selectedOptionsIds.includes('all')}
                          onChange={handleOptionsChange}
                          value="all" />}
                      label="Todas"
                    /> 
                    : <></>}
                {listOptions.map(option => (
                    <FormControlLabel
                        className='setting-checkbox-item'
                        key={option.id.toString()}
                        control={<Checkbox
                          onChange={handleOptionsChange}
                          value={option.id.toString()} 
                          disabled={selectedOptionsIds.includes('all')}
                          checked={selectedOptionsIds.includes(option.id.toString())}
                          />}
                        label={option?.displayName} 
                />
                  ))}
                  
              </FormGroup>
          </FormControl>
        </>
    )
}

export default CheckboxList