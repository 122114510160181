/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Topbar from '../../components/common/topbar/Topbar'
import StockDataTable from '../../components/common/datatable/Datatable'
import NewsanIcons from '../../components/common/Icons'
import { useNavigate } from 'react-router-dom'
import { Company, DocumentType, Receipt, TableAction, TableDetailCell, TableHeaderCell } from '../../model/models-module'
import './Receipt.css';
import { Box, CircularProgress, FormControl, MenuItem, Select, Tooltip, Typography } from '@mui/material'
import useData from '../../components/hooks/useData'
import { ResponseDataPaggedDto } from '../../model/dto-module'
import InputFilter from '../../components/common/input-filter/InputFilter'
import InputNumberRange from '../../components/common/input-number-range/InputNumberRange'
import InputDateRange from '../../components/common/input-date-range/InputDateRange'
import { formatDate, openFile } from '../../utils/CommonUtils'
import moment from 'moment'
import DocumentService from '../../services/DocumentService'
import { DocumentByQueryFilter } from '../../dto/document-find-by-query.dto'
import { CleaningServicesOutlined } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search';


const ReceiptView = () => {
  const [dataHeader, setDataHeader] = useState<TableHeaderCell[]>([]); 
  const [dataShow, setDataShow] = useState<TableDetailCell[]>([]); 
  const [dataCompanies, setDataCompanies] = useState<Company[]>([]);
  const [dataDocumentTypes, setDataDocumentTypes] = useState<DocumentType[]>([]);
  const [page, setPage] = useState<number>(1);
  const [dataTo, setDataTo] = useState<number>(10);
  const [dataOffset, setDataOffset] = useState<number>(0);
  const [sortArg, setSortArg] = useState<string>('');
  const [sort] = useState<Map<string, string>>(new Map());
  const [newSort, setNewSort] = useState<number>(0);
  const [countItems, setCountItems] = useState<number>(0);
  const [appendRow, setAppendRow] = useState<any>(null);
  const [tipoComp, setTipoComp] = useState<number>(-1);
  const [expandFilters, setExpandFilters] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [service] = useState<DocumentService>(DocumentService.getInstance());

  const [amountWithoutTaxFrom, setAmountWithoutTaxFrom] = useState<number>(0);
  const [amountWithoutTaxTo, setAmountWithoutTaxTo] = useState<number>(0);
  const [amountWithTaxFrom, setAmountWithTaxFrom] = useState<number>(0);
  const [amountWithTaxTo, setAmountWithTaxTo] = useState<number>(0);
  const [issueDateFrom, setIssueDateFrom] = useState<Date | undefined>();
  const [issueDateTo, setIssueDateTo] = useState<Date | undefined>();


  // amountWithoutTaxFrom: Importe sin impuestos desde
  // amountWithoutTaxTo:Importe sin impuestos hasta
  // amountWithTaxFrom:Importe con impuestos desde
  // amountWithTaxTo:Importe con in impuestos hasta 


  const [companyFilter, setCompanyFilter] = useState<number>(-1);
  const [orderNumber, setOrderNumberEBS] = useState<string>('');
  const [externalOrderNumber, setExternalOrderNumber] = useState<string>('');
  const [storeCode, setStoreCode] = useState<string>('');
  const [receiptNumber, setReceiptNumber] = useState<string>('');
  const [client, setClient] = useState<string>('');
  // let endpoint = `/documents?limit=${dataTo}&offset=${dataOffset}`;  
  const [loading, setLoading] = useState<boolean>(false);


  const findAll = useCallback( async ({limit = dataTo, offset = dataOffset, pSortArg= sortArg, pClient = client, pReceiptNumber= receiptNumber, pOrderNumber= orderNumber, pStoreCode = storeCode, pExternalOrderNumber = externalOrderNumber, pTipoComp = tipoComp, pCompanyFilter = companyFilter, pAmountWithoutTaxFrom = amountWithoutTaxFrom, pAmountWithoutTaxTo = amountWithoutTaxTo, pAmountWithTaxFrom= amountWithTaxFrom, pAmountWithTaxTo = amountWithTaxTo, pIssueDateFrom = issueDateFrom, pIssueDateTo = issueDateTo} :  DocumentByQueryFilter = {})=>{
    setLoading(true);
    const data = await service.findByQuery({limit, offset, pSortArg, pClient, pReceiptNumber, pOrderNumber, pStoreCode, pExternalOrderNumber, pTipoComp, pCompanyFilter, pAmountWithoutTaxFrom, pAmountWithoutTaxTo, pAmountWithTaxFrom, pAmountWithTaxTo, pIssueDateFrom, pIssueDateTo});

      setCountItems(data.paging!.total);
      setDataShow(data.results!.map((d: any, i: number)=>{
        d.issueDate = formatDate(moment(d.issueDate).add(1, 'day').toDate());
        return new TableDetailCell(
          d,
          [
          new TableAction('Ver detalle' ,(row:Receipt)=> {
            navigate(`/receipts/${row.id}`);
          } ),
          new TableAction('Descargar comprobante' ,(row:any)=> openFile(row.url) ),
        ],
        new Map<string, React.ReactNode>([
          ['nameRecipient',
            d.nameRecipient.length < 14 ? <Typography fontSize={'0.875rem'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} overflow={'hidden'}>{d.nameRecipient}</Typography> : <Tooltip title={d.nameRecipient}><Typography  whiteSpace={'nowrap'} textOverflow={'ellipsis'} overflow={'hidden'} fontSize={'0.875rem'} maxWidth={105}>{d.nameRecipient}</Typography></Tooltip>
          ],
          ['company.name',
          d.company.name.length < 8 ? <Typography fontSize={'0.875rem'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} overflow={'hidden'}>{d.company.name}</Typography> : <Tooltip title={d.company.name}><Typography  whiteSpace={'nowrap'} textOverflow={'ellipsis'} overflow={'hidden'} fontSize={'0.875rem'} maxWidth={105}>{d.company.name}</Typography></Tooltip>
          ],
          ['amountWithoutTax',
            <div key={i} style={{maxWidth: '110px'}}>${d.amountWithoutTax.toFixed(2)}</div>
          ],
          ['amountWithTax',
            <div key={i} style={{maxWidth: '110px'}}>${d.amountWithTax.toFixed(2)}</div>
          ]
        ])
      );
      }));
      setLoading(false);
  }, [sortArg, client, receiptNumber, orderNumber, storeCode, externalOrderNumber, tipoComp, companyFilter, amountWithoutTaxFrom, amountWithoutTaxTo, amountWithTaxFrom, amountWithTaxTo, issueDateFrom, issueDateTo])

  const cleanFilters = useCallback(()=>{
    setReceiptNumber('');
    setTipoComp(0);
    setIssueDateFrom(undefined);
    setIssueDateTo(undefined);
    setClient('');
    setCompanyFilter(0);
    setOrderNumberEBS('');
    setExternalOrderNumber('');
    setStoreCode('');
    setAmountWithTaxFrom(0);
    setAmountWithTaxTo(0);
    setAmountWithoutTaxFrom(0);
    setAmountWithoutTaxTo(0);
  }, []);


  useMemo(()=>{
    findAll();
    setInitialized(true);
  }, []);


  // const [loading, error, data] = useData<ResponseDataPaggedDto>(endpoint, 'GET')
  const [loadingEmp, errorEmp, dataEmp] = useData<ResponseDataPaggedDto>('/companies', 'GET');
  const [loadingDocType, errorDocType, dataDocType] = useData<ResponseDataPaggedDto>('/document-types', 'GET');
  const navigate = useNavigate();


  useEffect(()=>{
      if(appendRow === null) {
        return;
      }
      const copyRow = Object.assign({}, {data: appendRow.row.data, actions:[
          new TableAction('Guardar' ,(row:any)=> alert(row) ,NewsanIcons.PLUS),
      ], 
      customDefinitions: new Map<string, React.ReactNode>([
          ['col', <input key={`custom-${dataShow.length}`}></input>],
          ['col2', <input key={`custom2-${dataShow.length}`}></input>],
        ])
      });
      let aux: any[] = Object.assign([], dataShow); 
      aux = aux.slice(0, appendRow.index+1);
      const listPost = aux.slice(appendRow.index+1, dataShow.length);
      aux.push(copyRow);
      aux = aux.concat(listPost);
      setAppendRow(null);
      setDataShow(aux);

  }, [appendRow]);

  useEffect(()=>{
    if(newSort > 0) {

      let args = '';
      sort.forEach((value)=>{
        if(args === '') {
          args += value;
        } else {
          args += `,${value}`;
        }
      });
      setSortArg(args);
      setNewSort(0);
    }
  }, [newSort, setSortArg, setNewSort]);

  const onSort = (key: string, order: string)=>{ 
    if(order === ''){ 
      sort.delete(key)
    } else {
      sort.set(key, `${key}:${order}`);
    }
    setNewSort(1);
  };

  useEffect(()=>{
    if(!errorEmp && !loadingEmp && dataEmp != null) {
      setDataCompanies(dataEmp.results ?? []);
    }
    if(!errorDocType && !loadingDocType && dataDocType != null) {
      setDataDocumentTypes(dataDocType.results ?? []);
    }
    setDataHeader([
      new TableHeaderCell({label: 'Nº Comp.', key: 'number', sorteable: true, headerStyle: {minWidth: '100px'}}), 
      new TableHeaderCell({label: 'Tipo', key: 'documentType.description', sorteable: true, colStyle: {minWidth: '100px'}}),
      new TableHeaderCell({label: 'Fecha', key: 'issueDate', sorteable: true, colStyle: {minWidth: '80px'}}),
      new TableHeaderCell({label: 'Cliente', key: 'nameRecipient', sorteable: true}),
      new TableHeaderCell({label: 'Empresa', key: 'company.name', sorteable: true}),
      new TableHeaderCell({label: 'Neto', key: 'amountWithoutTax', sorteable: true, colStyle: {minWidth: '105px', textAlign: 'right'}}),
      new TableHeaderCell({label: 'Bruto', key: 'amountWithTax', sorteable: true, colStyle: {minWidth: '105px', textAlign: 'right'}}),
      new TableHeaderCell({label: 'Pedido EBS', key: 'ebsOrderNumber', sorteable: false, headerStyle: {minWidth: '115px'}, colStyle:{textAlign: 'right'}}),
    ]);

  }, [setDataCompanies, setDataDocumentTypes, loadingDocType, loadingEmp]);


  return (
    <>
    
    {
    loading ?
      <div className='loadingPage'>
        <CircularProgress className='circleProgress'/>
      </div>
      : null
    }
    

    <Topbar title={'Comprobantes'} subtitle={'Desde aqui podras buscar, filtrar y descargar comprobantes: facturas, órdenes de compra, nota de crédito, etc.'}>
    </Topbar>
      <form onSubmit={()=>{findAll()}}>
    <div className={expandFilters ? 'panel-filters expanded' : 'panel-filters'}>

        <Box className='filter-row'>
          <Box className='filter-box'>
            <label htmlFor="">Nº Comp.</label>
            <InputFilter value={receiptNumber} placeholder={'Escribe el número'} onChange={(newVal)=>{
              if(initialized && receiptNumber !== (newVal ?? '')) {
                setReceiptNumber(newVal ?? '');
              }
      }} doActionOnEvent={(newVal)=>findAll({offset: 0, pReceiptNumber: newVal ?? ''})}></InputFilter>
          </Box>
          <Box className='filter-box'>
            <label htmlFor="">Tipo Comp.</label>
            <FormControl className='select-filter' size="small">
              <Select value={tipoComp} onChange={(e)=>{
                if(initialized && tipoComp !== Number(e.target.value)) {
                  setTipoComp(Number(e.target.value));
                }
      }}>
                <MenuItem value={-1}>
                  <em className='no-selection'>Seleccionar</em>
                </MenuItem>
                {
                  !loadingDocType ?
                  dataDocumentTypes.map((dt)=>{
                    return <MenuItem className='select-option' key={dt.id} value={dt.id}>{dt.description}</MenuItem>;
                  })
                  : null
                }
              </Select>
            </FormControl>
          </Box>
          <Box className='filter-box'>
            <label htmlFor="">Fecha emisión</label>
                <InputDateRange valueFrom={issueDateFrom} valueTo={issueDateTo}  onChange={(newVal)=>{
                  if(initialized && issueDateFrom !== newVal.from && issueDateTo !== newVal.to) {
                    setIssueDateFrom(newVal.from) ; 
                    setIssueDateTo(newVal.to);
                  }
                  }}></InputDateRange>
            
            {/* <FormControl className='select-filter' size="small">
              <Select value={tipoComp} placeholder={'Seleccionar'} onChange={(e)=>setTipoComp(Number(e.target.value))}>
                <MenuItem value={-1} >
                  <em className='no-selection'>Seleccionar</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl> */}
          </Box>
          <Box className='filter-box'>
            <label htmlFor="">Cliente</label>
            <InputFilter value={client} placeholder={'Nombre, DNI o CUIT'} onChange={(newVal)=>{
              if(initialized && client !== (newVal ?? '')) {
                setClient(newVal ?? '');
              }
                    }} doActionOnEvent={(newVal)=>findAll({offset: 0, pClient: newVal ?? ''})}></InputFilter>
          </Box>
          <Box className='filter-box'>
            <label htmlFor="">Empresa</label>
            <FormControl className='select-filter' size="small">
              <Select value={companyFilter} onChange={(e)=>{
                if(initialized && companyFilter !== Number(e.target.value)) {
                  setCompanyFilter(Number(e.target.value));
                }
                    }}>
                <MenuItem value={-1}>
                  <em className='no-selection'>Seleccionar</em>
                </MenuItem>
                {
                  !loadingEmp  ?
                  dataCompanies.map((c)=>{
                    return <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>;
                  })
                  : null
                }
              </Select>
            </FormControl>
          </Box>
          <Box className='filter-box'>
            <label htmlFor="">Nº Pedido EBS</label>
            <InputFilter value={orderNumber} placeholder={'Escribe el número'} onChange={(newVal)=>{
              if(initialized && orderNumber !== (newVal ?? '')) {
                setOrderNumberEBS(newVal ?? '');
              }
              }} doActionOnEvent={(newVal)=>findAll({offset: 0, pOrderNumber: newVal ?? ''})}></InputFilter>
          </Box>
          <Box className='filter-box-action'>
            <span className='clean' onClick={()=>{cleanFilters()}}><CleaningServicesOutlined /></span>
            <span className='search' onClick={()=>{findAll();}}><SearchIcon /></span>
          </Box>

          {
            expandFilters ?
            <>
              <Box className='filter-box'>
                <label htmlFor="">Nº Pedido Externo</label>
                <InputFilter value={externalOrderNumber} placeholder={'Escribe el número'} onChange={(newVal)=>{
                  if(initialized && externalOrderNumber !== (newVal ?? '')) {
                    setExternalOrderNumber(newVal ?? '');
                  }
                    }} doActionOnEvent={(newVal)=>findAll({offset: 0, pExternalOrderNumber: newVal ?? ''})}></InputFilter>
              </Box>
              <Box className='filter-box'>
                <label htmlFor="">Catálogo</label>
                <InputFilter value={storeCode} placeholder={'Escribe el código'} onChange={(newVal)=>{
                  if(initialized && storeCode !== (newVal ?? '')) {
                    setStoreCode(newVal ?? '');
                  }
                }} doActionOnEvent={(newVal)=>findAll({offset: 0, pStoreCode: newVal ?? ''})}></InputFilter>
              </Box>
              <Box className='filter-box'>
                <label htmlFor="">Importe bruto</label>
                <InputNumberRange valueFrom={amountWithTaxFrom} valueTo={amountWithTaxTo}  onChange={(newVal)=>{
                  if(initialized && amountWithTaxFrom !== newVal.from && amountWithTaxTo !== newVal.to) {
                    setAmountWithTaxFrom(newVal.from ?? 0) ; 
                    setAmountWithTaxTo(newVal.to ?? 0);
                  }
                  }}></InputNumberRange>
              </Box>
              <Box className='filter-box'>
                <label htmlFor="">Importe neto</label>
                <InputNumberRange valueFrom={amountWithoutTaxFrom} valueTo={amountWithoutTaxTo} onChange={(newVal)=>{
                  if(initialized && amountWithoutTaxFrom !== newVal.from && amountWithoutTaxTo !== newVal.to) {
                    setAmountWithoutTaxFrom(newVal.from ?? 0); 
                    setAmountWithoutTaxTo(newVal.to ?? 0);
                  }
                  }}></InputNumberRange>
              </Box> 
            </>
            : null
          }
        </Box>
      <Box className='collapsible-box'>
        <span onClick={()=>setExpandFilters(!expandFilters)}>
          {
          expandFilters ? 
          NewsanIcons.ARROW_COLLAPSE
          :
          NewsanIcons.ARROW_EXPAND
        }
        </span>
      </Box>

    </div>
      </form>
    {
      <div style={{ paddingBottom: '42px'}}>
        <StockDataTable className='tableReceipts' headers={dataHeader} rows={dataShow}  onPageSizeChange={(size)=>{setDataTo(size); setDataOffset(0); setPage(1);  findAll({offset: 0, limit: size})}}
        countItems={countItems}  rowsPerPage={dataTo} hasActions={true} 
        appendOnScrollToBottom={false} pageSelected={page} pageable={true}
        selectable={true}  onSortChange={onSort} onPageChange={(page: number)=>{ setPage(page); setDataOffset((page-1)*dataTo); findAll({offset: (page-1)*dataTo}) }}
        >
        </StockDataTable>
      </div>
    }
    </>
  )
}

export default ReceiptView;
