import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'
import colorConfigs from '../../configs/colorConfigs'
import Sidebar from '../common/Sidebar'
// import Topbar from '../common/Topbar'

const MainLayout = () => {
  return (
    <Box sx={{ display: 'flex', background: '#f5f5f5' }}>
      
      <Box
        component='nav'
        sx={{
          flexShrink: 0,
        }}
      >
      <Sidebar />
      </Box>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          padding: '20px',
          paddingLeft: '40px',
          // width: `calc(100% - ${sizeConfigs.sidebar.width})`,
          minHeight: '100vh',
          marginBottom: '20px',
          backgroundColor: colorConfigs.mainBg,
        }}
      >
        <Outlet />
      </Box>
    </Box>
  )
}

export default MainLayout
