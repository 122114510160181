/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

type MaskOptions = {
  prefix: string;
  suffix: string;
  includeThousandsSeparator: boolean;
  thousandsSeparatorSymbol: string;
  allowDecimal: boolean;
  decimalSymbol: string;
  decimalLimit: number;
  integerLimit: number;
  allowNegative: boolean;
  allowLeadingZeroes: boolean;
}

type Props = {
    value: any;
    type?: string;
    placeholder?: string;
    maskOptions?: MaskOptions;
    onChange(newVal?:string):void;
    doActionOnEvent?(newVal?:string):void;
}

const defaultMaskOptions: MaskOptions  = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 9, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

const generateInput = (inputValue: any, handleInputChange: (event: any) => void,  {type, placeholder,maskOptions=defaultMaskOptions, doActionOnEvent } : Props, showEnterIcon: boolean = false, setShowEnter: any) => {
  

  switch(type){
    case 'currency': 
    return <div className='input-currency-container'>
            <MaskedInput onKeyDown={(e) => {
    if (e.key === 'Tab') {
      e.stopPropagation();
    }
  }} className='input-currency-field' mask={createNumberMask(maskOptions)} value={inputValue} onChange={handleInputChange} placeholder={placeholder}></MaskedInput>
            <i></i>
            </div>
    case 'event':
      return <div>
        <input onKeyDown={(e) => {
          setShowEnter(true);
          if (e.key === 'Tab' || e.key === 'Enter') {
            e.stopPropagation();
            setShowEnter(false);
            if(doActionOnEvent) {
              doActionOnEvent(e.currentTarget.value);
            }
          }
        }} type={'text'} placeholder={placeholder} value={inputValue} onChange={handleInputChange}/>
        
        {
          showEnterIcon ?
          <KeyboardReturnIcon></KeyboardReturnIcon>
          : null
        }
      </div>
    default:
    return <input onKeyDown={(e) => {
    if (e.key === 'Tab') {
      e.stopPropagation();
    }
    if (e.key.toLocaleLowerCase() === 'enter') {
      e.stopPropagation();
      if(doActionOnEvent) {
        doActionOnEvent(e.currentTarget.value);
      }
    }
  }} type={type} placeholder={placeholder} value={inputValue} onChange={handleInputChange} />
  }

}

const unformattedValue = ({type, inputValue, maskOptions = defaultMaskOptions} : {type: string, inputValue: string, maskOptions?: MaskOptions} ) => {

        switch(type) {
          case 'currency': 
            return inputValue  ? String(inputValue).replaceAll(maskOptions!.thousandsSeparatorSymbol, '').replace(maskOptions!.decimalSymbol, '.') : null;
          default:
            return inputValue;
        }
}

const InputFilter = (props: Props)=> {

    const [inputValue, setInputValue] = useState('');
    const [showEnterIcon, setShowEnterIcon] = useState(false);
    const [debouncedInputValue, setDebouncedInputValue] = useState<string>(props.value);

    const handleInputChange = (event: any) => {
        switch(props.type) {
          case 'date':
            if(event.target.value) {
              setInputValue(moment(event.target.value).format('yyyy-MM-DD'));

            } else {
              setInputValue(event.target.value);
            }
            break;            
          default:
            setInputValue(event.target.value);
            break;
        }

    }


    useEffect(() => {

      const delayInputTimeoutId = setTimeout(() => {
        const val = unformattedValue({type: props.type!, inputValue, maskOptions: props.maskOptions})
        setDebouncedInputValue(val ?? '');
      }, 500);
      return () => clearTimeout(delayInputTimeoutId);

    }, [inputValue, 500]);

    useEffect(
    ()=>{
      props.onChange(debouncedInputValue);
    },
    [debouncedInputValue]);

    useEffect(
    ()=>{
      switch(props.type) {
        case 'date':
          setInputValue(moment(props.value).format('yyyy-MM-DD'));
          break;            
        default:
          setInputValue(props.value);
          break;
      }
    },
    [props.value]);

    return (
        <>
        {
          inputValue != undefined ?
          generateInput(inputValue, handleInputChange, props, showEnterIcon, setShowEnterIcon)
          : null
        }
        </>
      );
}


export default InputFilter;