import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';

interface PopupSuccessErrorProps {
    open: boolean;
    onClose: () => void;
    loading: boolean;
    success: boolean;
    error: boolean;
    successMessage: string | null;
    errorMessage: string | null;
  }

const PopupSuccessError = ({ open, onClose, loading, success, error, successMessage, errorMessage }: PopupSuccessErrorProps) => {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{loading ? 'Guardando' : ''}</DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : success ? (
            <Alert severity="success">{successMessage}</Alert>
          ) : error ? (
            <Alert severity="error">{errorMessage}</Alert>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default PopupSuccessError;
