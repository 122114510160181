import React, { useState, useEffect, useCallback } from 'react'
import Topbar from '../../components/common/topbar/Topbar'
import './MassiveDownload.css';
import { Box, FormControl, MenuItem, Select, Button } from '@mui/material'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import InputFilter from '../../components/common/input-filter/InputFilter'
import InputDateRange from '../../components/common/input-date-range/InputDateRange'
import { ResponseDataPaggedDto } from '../../model/dto-module';
import useData from '../../components/hooks/useData';
import { Company, DocumentType } from '../../model/models-module';
import { formatParamDate } from '../../utils/CommonUtils'
import axios, { AxiosResponse } from 'axios';
import AxiosInterceptor from '../../services/http/AxiosInterceptor';
import PopupSuccessError from '../../components/common/popup-success-error/PopupSuccessError';
import { CleaningServicesOutlined } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search';

const clientTrigger = AxiosInterceptor.addInterceptor(process.env.REACT_APP_API_BASE_URL)

const MassiveDownload = () => {
    const [tipoComp, setTipoComp] = useState<number>(-1)
    const [issueDateFrom, setIssueDateFrom] = useState<Date>();
    const [issueDateTo, setIssueDateTo] = useState<Date>();
    const [companyFilter, setCompanyFilter] = useState<number>(-1)
    const [client, setClient] = useState('')

    const [loadingComp, errorComp, dataComp] = useData<ResponseDataPaggedDto>('/companies', 'GET');
    const [loadingDocType, errorDocType, dataDocType] = useData<ResponseDataPaggedDto>('/document-types', 'GET');
    const [companies, setCompanies] = useState<Company[]>([])
    const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([])
    const [showBox, setShowBox] = useState<boolean>(false)
    const [showPopup, setShowPopup] = useState<boolean>(false)
    const [showButton, setShowButton] = useState<boolean>(false)

    let endpointQuery = ''
    const [endpointDownload, setEndpointDownload] = useState('')
    const [loadingResponse, setLoadingResponse] = useState(false);
    const [errorResponse, setErrorResponse] = useState<string | null>(null);
    const [dataResponse, setDataResponse] = useState<AxiosResponse | null>(null);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [errorDownload, setErrorDownload] = useState<string | null>(null);
    const [dataDownload, setDataDownload] = useState<string | null>(null);


  const cleanFilters = useCallback(()=>{
    setTipoComp(-1);
    setIssueDateFrom(undefined);
    setIssueDateTo(undefined);
    setClient('');
    setCompanyFilter(-1);
  }, []);

    const appendStringParam = (value: string, paramCode:string) =>{
        let param: string = '';
    
        if(value) {
          param += `&${paramCode}=${value}`;
        }
    
        return param;
      }
    
      const appendNumberParam = (value: number, paramCode: string) =>{
        let param: string = '';
    
        if(value > 0) {
          param += `&${paramCode}=${value}`;
        }
    
        return param;
      }
    
      const appendDateParam = (value?: Date, paramCode?: string) =>{
        let param: string = '';
        if(value) {
          param += `&${paramCode}=${formatParamDate(value)}`;
        }
    
        return param;
      }

      const searchDocuments = async () => {
        try{
            setLoadingResponse(true)
            endpointQuery = 'documents/count-documents-to-download?';
            endpointQuery += appendStringParam(client,'client');
            endpointQuery += appendNumberParam(tipoComp,'documentType');
            endpointQuery += appendNumberParam(companyFilter,'company');
            endpointQuery += appendDateParam(issueDateFrom,'issueDateFrom');
            endpointQuery += appendDateParam(issueDateTo,'issueDateTo');
            setEndpointDownload(endpointQuery.replace('count-', ''))
            const data = await clientTrigger.get(endpointQuery)
            setDataResponse(data)
            setErrorResponse(null)
        } catch (error) {
          if (axios.isAxiosError(error)) {
            setErrorResponse(error.response?.data.message);
          } else if (error instanceof Error) {
            setErrorResponse(error.message);
          } else {
            setErrorResponse('Hubo un error en la solicitud');
          }
        } finally {
          setLoadingResponse(false);
        } 
      }

      const downloadFiles = async () => {
        try{
            setLoadingDownload(true)
            const response = await clientTrigger.get(endpointDownload, {
              responseType: 'blob',
            })

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'comprobantes.zip'); // Nombre que se dará al archivo descargado
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            setDataDownload(response.data.results)
            setErrorDownload(null)
        } catch (error) {
          if (axios.isAxiosError(error)) {
            setErrorDownload(error.response?.data.message);
          } else if (error instanceof Error) {
            setErrorDownload(error.message);
          } else {
            setErrorDownload('Hubo un error en la solicitud');
          }
        } finally {
          setLoadingDownload(false);
        } 
      }


    useEffect(()=>{
        if(!errorComp && !loadingComp && dataComp != null) {
            setCompanies(dataComp.results ?? []);
        }
        if(!errorDocType && !loadingDocType && dataDocType != null) {
            setDocumentTypes(dataDocType.results ?? []);
        }
    }, [loadingDocType, loadingComp, setDocumentTypes, setCompanies])

    useEffect(()=>{
      if(dataResponse) {
        const visible = dataResponse.data.paging.total < 250 && dataResponse.data.paging.total > 0
        setShowButton(visible);
      }
  }, [setShowButton, dataResponse])


    return (
        <>
        <Topbar title={'Descarga de Comprobantes'} subtitle={''}>
        </Topbar>
        <div className='panel-filters-massive'>
            <Box className='filter-row-massive'>
                <Box className='filter-box-massive'>
                    <label htmlFor="">Tipo Comp.</label>
                    <FormControl className='select-filter' size="small">
                    <Select value={tipoComp} onChange={(e)=>{setTipoComp(Number(e.target.value))}}>
                        <MenuItem value={-1}>
                        <em className='no-selection'>Seleccionar</em>
                        </MenuItem>
                        {
                        !loadingDocType ?
                        documentTypes.map((dt)=>{
                            return <MenuItem className='select-option' key={dt.id} value={dt.id}>{dt.description}</MenuItem>;
                        })
                        : null
                        }
                    </Select>
                    </FormControl>
                </Box>
                <Box className='filter-box-massive'>
                    <label htmlFor="">Fecha emisión</label>
                        <InputDateRange valueFrom={issueDateFrom} valueTo={issueDateTo}  onChange={(newVal)=>{
                            setIssueDateFrom(newVal.from) ; 
                            setIssueDateTo(newVal.to);
                        }}></InputDateRange>
                </Box>
                <Box className='filter-box-massive'>
                    <label htmlFor="">Cliente</label>
                    <InputFilter value={client} placeholder={'Nombre, DNI o CUIT'} onChange={(newVal)=>{setClient(newVal ?? '')}}></InputFilter>
                </Box>
                <Box className='filter-box-massive'>
                    <label htmlFor="">Empresa</label>
                    <FormControl className='select-filter' size="small">
                    <Select value={companyFilter} onChange={(e)=>{setCompanyFilter(Number(e.target.value))}}>
                        <MenuItem value={-1}>
                        <em className='no-selection'>Seleccionar</em>
                        </MenuItem>
                        {
                        !loadingComp ?
                        companies.map((dt)=>{
                            return <MenuItem className='select-option' key={dt.id} value={dt.id}>{dt.name}</MenuItem>;
                        })
                        : null
                        }
                    </Select>
                    </FormControl>
                </Box>
                {/* <Button
                variant='contained'
                onClick={() => {
                    setShowBox(true);
                    searchDocuments();
                }}
                className={'btn-warehouse button-newsan-grey'}
              >
                {'Buscar'}
              </Button> */}
              <Box className='filter-box-action'>
                <span className='clean' onClick={()=>{
                  cleanFilters();

                }}><CleaningServicesOutlined /></span>
                <span className='search' onClick={()=>{setShowBox(true); searchDocuments();}}><SearchIcon /></span>
              </Box>
            </Box>
        </div>
        {showBox ? (
            <Box>
            {
                loadingResponse ?
                <>Cargando..</>
                : (
                errorResponse ?
                <div className='panel-result-massive-error'>{errorResponse}</div>
                : 
                <div className='panel-result-massive'>
                    <span>{dataResponse?.data.results}</span>
                    {showButton ? <Button className='massive-download-button' onClick={() => {
                        setShowPopup(true)
                        downloadFiles()
                        }}>
                        <FileDownloadOutlinedIcon/>
                        Descargar Comprobantes
                    </Button> : <></>}
                </div>
                )
            }
            </Box>
        ) :
        <></>
        }
        {
            showPopup ? (<PopupSuccessError
            open={showPopup}
            onClose={() =>{ 
                setShowPopup(false);
            }}
            loading={loadingDownload}
            success={dataDownload !== null}
            error={errorDownload !== null}
            successMessage={'Descargado con exito'}
            errorMessage={'EEEERROR'}
            />)
        :
        <></>
        }
        </>
        
    )
}

export default MassiveDownload