/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, FormControl, MenuItem, Select } from '@mui/material'
import Topbar from '../../components/common/topbar/Topbar'
import DataTable from '../../components/common/datatable/Datatable'
import { useEffect, useState } from 'react'
import NewsanIcons from '../../components/common/Icons'
import './Templates.css'
import { Company, ConfigTemplate, DocumentType, TableAction, TableDetailCell, Template, Channel} from '../../model/models-module'
import CloseIcon from '@mui/icons-material/Close'
import useData from '../../components/hooks/useData'
import { ResponseDataPaggedDto } from '../../model/dto-module'
import CheckboxList from '../../components/common/checkbox-list/CheckboxList'
import axios from 'axios';
import AxiosInterceptor from '../../services/http/AxiosInterceptor';
import PopupSuccessError from '../../components/common/popup-success-error/PopupSuccessError'

const dataHeader = [
  { label: 'Nombre', key: 'col', sorteable: false },
  { label: 'Plantilla', key: 'col2', sorteable: false },
  { label: 'Empresa', key: 'col3', sorteable: false },
  { label: 'Tipo Comprobante', key: 'col4', sorteable: false },
  { label: 'Canal', key: 'col5', sorteable: false}
]
  
type formatListType = {
  id: number,
  displayName?: string
}

type typeBody = {
  templateId: number,
  name: string,
  companiesId: number[],
  documentTypesId: number[],
  channelsId: number[],
  description: string
}

const client = AxiosInterceptor.addInterceptor(process.env.REACT_APP_API_BASE_URL)

const Templates = () => {
  const [dataShow, setDataShow] = useState<any>([])
  const [dataTo, setDataTo] = useState<number>(20)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [showForm, setShowForm] = useState<boolean>(false)
  const [newEditItem, setNewEditItem] = useState<ConfigTemplate | undefined>()
  const [dataCompanies, setDataCompanies] = useState<Company[]>([]);
  const [dataDocumentTypes, setDataDocumentTypes] = useState<DocumentType[]>([]);
  const [dataTemplates, setDataTemplates] = useState<Template[]>([]);
  const [dataChannels, setDataChannels] = useState<Channel[]>([]);
  const [loadingEmp, errorEmp, dataEmp] = useData<ResponseDataPaggedDto>('/companies', 'GET');
  const [loadingDocType, errorDocType, dataDocType] = useData<ResponseDataPaggedDto>('/document-types', 'GET');
  const [loadingTempl, errorTempl, dataTempl]= useData<ResponseDataPaggedDto>('/template', 'GET')
  const [loadingChan, errorChan, dataChan]= useData<ResponseDataPaggedDto>('/channels', 'GET')
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<string[]>([]);
  const [formatCompanyList, setFormatCompanyList] = useState<formatListType[]>([]);
  const [formatDocsList, setFormatDocsList] = useState<formatListType[]>([]);
  const [formatChannelList, setFormatChannelList] = useState<formatListType[]>([])
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [selectedName, setSelectedName] = useState<string>('');
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
  const [countItems, setCountItems] = useState<number>(0);
  const [dataOffset, setDataOffset] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [templateSettingsEndpoint, setTemplateSettingsEndpoint] = useState<string>('');
  const [loadingConfigTempl, errorConfigTempl, dataConfigTempl]= useData<ResponseDataPaggedDto>(templateSettingsEndpoint, 'GET')
  
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [errorResponse, setErrorResponse] = useState<string | null>(null);
  const [dataResponse, setDataResponse] = useState<string | null>(null);

  const [showPopup, setShowPopup] = useState<boolean>(false)

  const fetchData = async ( body?: typeBody) => {
    try{
      setLoadingResponse(true)
      if (isEdit){
        await client.patch(`/template-settings/${newEditItem?.id}`, body)
        setDataResponse('Modificado con exito!')
        setErrorResponse(null)
      }else{
        await client.post('/template-settings', body)
        setDataResponse('Creado con exito')
        setErrorResponse(null)
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setErrorResponse(error.response?.data.message);
      } else if (error instanceof Error) {
        setErrorResponse(error.message);
      } else {
        setErrorResponse('Hubo un error en la solicitud');
      }
    } finally {
      setLoadingResponse(false);
    } 
  }

  const formatForm = () => {
    setSelectedCompanies([]);
    setSelectedDocumentTypes([]);
    setSelectedTemplate('');
    setSelectedName('');
    setSelectedChannels([]);
  }

  const saveFormInfo = () => {
    if (!selectedChannels || !selectedCompanies || !selectedDocumentTypes || !selectedName || !selectedTemplate){
      alert('Se deben completar todos los campos')
    } else {
      setSelectedChannels(selectedChannels.filter(a => a != 'all'))
      setSelectedCompanies(selectedCompanies.filter(a => a != 'all'))
      setSelectedDocumentTypes(selectedDocumentTypes.filter(a => a != 'all'))
      const temporalObject: typeBody = {
        templateId: dataTemplates.find(a => a.name === selectedTemplate)?.id || 0,
        name: selectedName,
        companiesId: selectedCompanies.map(company => parseInt(company)),
        documentTypesId: selectedDocumentTypes.map(docType => parseInt(docType)),
        channelsId: selectedChannels.map(channel => parseInt(channel)),
        description: 'No valida'
      }
      setShowPopup(true);
      fetchData(temporalObject)
      formatForm()
    }
  }

  const deleteTemplateConfig = async (id: number) => {
    try{
      setLoadingResponse(true)
        await client.delete(`/template-settings/${id}`)
        setDataResponse('Eliminado con exito!')
        setErrorResponse(null)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setErrorResponse(error.response?.data.message);
      } else if (error instanceof Error) {
        setErrorResponse(error.message);
      } else {
        setErrorResponse('Hubo un error en la solicitud');
      }
    } finally {
      setLoadingResponse(false);
    } 
  }

  useEffect(() => {
    setTemplateSettingsEndpoint(`/template-settings?limit=${dataTo}&offset=${dataOffset}`);

    if (!errorConfigTempl && dataConfigTempl && dataConfigTempl.results) {
      setCountItems(dataConfigTempl?.paging.total);
      setDataShow(dataConfigTempl.results!.map((r: ConfigTemplate) => {
          const col3Names = r.companies.map((comp: Company, index) => comp.name + (r.companies?.length - 1 > index ? ' - ' : ' '));
          const col4Names = r.documentTypes.map((docType: DocumentType, index) => docType.description + (r.documentTypes?.length - 1 > index ? ' - ' : ' '));
          const col5Names = r.channels.map((channel: DocumentType, index) => channel.description + (r.channels?.length - 1 > index ? ' - ' : ' '));
          return new TableDetailCell({ col: r.name, col2: r.template.name, col3: col3Names, col4: col4Names, col5: col5Names}, [
            new TableAction('Editar configuración de plantilla', () => {
              formatForm()
              setNewEditItem(r)
              setIsEdit(true)
              setShowForm(true)
            }),
            new TableAction('Eliminar configuración de plantilla', () => {
              setShowPopup(true);
              deleteTemplateConfig(r.id)
            }),
          ])
      })

      )
    }
  }, [setDataShow, setCountItems,loadingConfigTempl, dataConfigTempl, dataTo, dataOffset, templateSettingsEndpoint])

  useEffect(()=>{
    if(!errorEmp && !loadingEmp && dataEmp != null) {
      setDataCompanies(dataEmp.results ?? []);
    }
    if(!errorDocType && !loadingDocType && dataDocType != null) {
      setDataDocumentTypes(dataDocType.results ?? []);
    }

    if(!errorTempl && !loadingTempl && dataTempl != null) {
      setDataTemplates(dataTempl.results ?? []);
    }

    if(!errorChan && !loadingChan && dataChan != null) {
      setDataChannels(dataChan.results ?? []);
    }

    if(newEditItem && isEdit){
      setSelectedTemplate(newEditItem.template?.name ? newEditItem.template.name : '');
      setSelectedName(newEditItem.name ? newEditItem.name : '')
      let temporalArray: string[] = []
      newEditItem.companies?.map(companies => {
        temporalArray.push(companies.id.toString())
      })
      setSelectedCompanies(temporalArray)
      temporalArray = []
      newEditItem.documentTypes?.map(doc => {
        temporalArray.push(doc.id.toString())
      })
      setSelectedDocumentTypes(temporalArray)
      temporalArray = []
      newEditItem.channels?.map(doc => {
        temporalArray.push(doc.id.toString())
      })
      setSelectedChannels(temporalArray)
    }

  }, [setDataCompanies, setDataDocumentTypes, setDataChannels, setDataTemplates, loadingChan, loadingTempl ,loadingDocType, loadingEmp, newEditItem, isEdit]);

  useEffect(() => {
    const temporalCompanyArray : formatListType[] = []
    dataCompanies.forEach(company => {
      temporalCompanyArray.push({id: company.id, displayName: company?.name})
    })
    setFormatCompanyList(temporalCompanyArray)

    const temporalDocsArray : formatListType[] = []
    dataDocumentTypes.forEach(docs => {
      temporalDocsArray.push({id: docs.id, displayName: docs?.description})
    })
    setFormatDocsList(temporalDocsArray)

    const temporalChannelArray : formatListType[] = []
    dataChannels.forEach(channel => {
      temporalChannelArray.push({id: channel.id, displayName: channel?.description})
    })
    setFormatChannelList(temporalChannelArray)
  }, [dataCompanies, dataDocumentTypes, dataChannels]);

  if(loadingEmp || loadingDocType) {
    return <></>;
  }

  return (
    <div>
      <Topbar title={'Configuración de Plantillas'} subtitle={''}></Topbar>

      {
        loadingConfigTempl ?
        <>Cargando..</>
        : (
          errorConfigTempl ?
          <>Ha habido un problema!</>
          :
        <div
        className={
          showForm ? 'table-templates-container form-visible' : 'table-templates-container'
        }
        >
          <DataTable
            headers={dataHeader}
            rows={dataShow}
            countItems={countItems}
            rowsPerPage={dataTo}
            hasActions={true}
            appendOnScrollToBottom={false}
            onPageSizeChange={(size) => {setDataTo(size); setDataOffset(0); setPage(1);}}
            onPageChange={(page: number)=>{ setPage(page); setDataOffset((page-1)*dataTo) }}
            pageSelected={page}
            pageable={true}
            selectable={false}
          ></DataTable>
        </div>
          ) 
      }
      {showForm ? (
        <div className='abm-container'>
          <div className={isEdit ? 'add-form-container edit' : 'add-form-container'}>
            <span className='title-form'>
              {NewsanIcons.ADD_STORE}{' '}
              <span className='title-form-text'>{isEdit ? 'Editar la' : 'Nueva'} Configuración</span>{' '}
            </span>
            <CloseIcon
              className='form-close-button'
              onClick={() => {
                formatForm()
                setShowForm(false)
                setIsEdit(false)
                setNewEditItem(undefined)
              }}
            ></CloseIcon>
            <span className='setting-attr-title'>Nombre configuración</span>
            <input
              type='text'
              className='setting-attr-input'
              placeholder='Nombre configuración'
              defaultValue={selectedName}
              onChange={(e) => {
                setSelectedName(e.target.value);
              }}
            />

            <span className='setting-attr-title'>Plantilla</span>

            <FormControl className='select-filter' size='small'>
              <Select
                value={selectedTemplate}
                onChange={(e) => {
                  setSelectedTemplate(e.target.value);
                }}
              >
                <MenuItem value={''}>
                  <em className='no-selection'>Seleccionar</em>
                </MenuItem>
                {dataTemplates.map(templateOtion => {
                  return(
                    <MenuItem className='select-option' key={templateOtion.id} value={templateOtion.name}>
                      {templateOtion.name}
                    </MenuItem>
                 )})}
              </Select>
            </FormControl>
            
            {formatCompanyList.length > 0 ? <CheckboxList listOptions={formatCompanyList} title='Empresa' allOption={true} selectedOptions={selectedCompanies} setSelectedOptions={setSelectedCompanies} /> : null}
            {formatDocsList.length > 0 ? <CheckboxList listOptions={formatDocsList} title='Tipo Comprobante' allOption={true} selectedOptions={selectedDocumentTypes} setSelectedOptions={setSelectedDocumentTypes}/>: null}
            {formatChannelList.length > 0 ? <CheckboxList listOptions={formatChannelList} title='Canal' allOption={false} selectedOptions={selectedChannels} setSelectedOptions={setSelectedChannels}/>: null}
          
            <div className='buttons-container'>
              <Button
                variant='contained'
                className='btn-newsan-cancel'
                onClick={() => {
                  setShowForm(false)
                  setIsEdit(false)
                  setNewEditItem(undefined)
                  setDataResponse(null)
                  setErrorResponse(null)
                  formatForm()
                }}
              >
                Cancelar
              </Button>
              <Button
                variant='contained'
                onClick={saveFormInfo}
                className={
                  isEdit ? 'btn-warehouse button-newsan-red' : 'btn-warehouse button-newsan-grey'
                }
              >
                {isEdit ? 'Guardar' : 'Cargar'}
              </Button>
            </div>
          </div>
        </div>
      ) : (
          <span className='btn-show-form' onClick={() => setShowForm(!showForm)}>
            {NewsanIcons.ADD_STORE}
          </span>
      )}
      {
        showPopup ? (<PopupSuccessError
          open={showPopup}
          onClose={() =>{ 
            setShowPopup(false); 
            setTemplateSettingsEndpoint('')
            setShowForm(false)
            setIsEdit(false)
            setNewEditItem(undefined)
            setDataResponse(null)
            setErrorResponse(null)
          }}
          loading={loadingResponse}
          success={dataResponse !== null}
          error={errorResponse !== null}
          successMessage={dataResponse}
          errorMessage={errorResponse}
        />)
      :
      <></>
      }
    </div>
  )
}

export default Templates
