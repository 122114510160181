/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import React, { ReactNode, useState } from 'react';
import { TableAction } from '../../../model/models-module';

type Param = {
    actions: TableAction[];
    row: any; 
    index: number;
    children?: ReactNode;
}

const ActionMenu = ({actions, row, index}: Param) => {

    const [anchorElActions, setAnchorElActions] = useState<null | HTMLElement>(null);

    const handleOpenActionMenu = (event: React.MouseEvent<HTMLElement>) => {
        if (anchorElActions !== event.currentTarget) {
            setAnchorElActions(event.currentTarget);
        }
    }

    const handleCloseActionMenu = () => {
        setAnchorElActions(null);
    }

    return (
        <>
                <span className='action-column'>
                    <Tooltip title={'Acciones'}>
                        <IconButton onClick={handleOpenActionMenu} sx={{ p: 0 }}>
                            <MoreVertOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </span>

                <Menu
                    sx={{ mt: '45px' }}
                    id={'menu-appbar'}
                    className='actions-menu'
                    anchorEl={anchorElActions}
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    open={Boolean(anchorElActions)}
                    onClose={handleCloseActionMenu}
                >
                    {
                        actions.map((act:TableAction) => (
                            <MenuItem key={`${act.label}${index}` } onClick={() => {handleCloseActionMenu(); act.action(row.data, index);}}>
                                <span className='menu-icon'>
                                    {act.icon}
                                </span>
                                <Typography textAlign='center'>{act.label}</Typography>
                            </MenuItem>
                            ))
                    }
                </Menu>
        </>

    )
};


export default ActionMenu;