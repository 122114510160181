/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, ReactNode } from 'react';

 class FileResult {
    fileId: number;
    filename: string;
    message: string;
    status: string; // 'pending' 'error' 'success'

    constructor(fileId = 0, message: string = '', filename: string, status: string = 'pending') {
        this.fileId = fileId;
        this.filename = filename;
        this.message = message;
        this.status = status;
    }

}


class TableAction {
    icon?: ReactElement;
    label: string;
    action:(row: any, index: number)=> void; 

    constructor(label: string, action:(row: any, index: number)=>void, icon?: ReactElement) {
        this.label = label;
        this.action = action;
        this.icon = icon;
    }
    
}

class TableHeaderCell {
    label: string; // Nombre a mostrar 
    key: string;
    sorteable?: boolean;
    sort?: string;
    align?: string;
    minWidth?: number;
    filter?: FilterTable;
    onSort?: ()=>void;
    onSelect?: (row: any)=>void;
    headerStyle?: any;
    colStyle?: any;

    constructor({label, key , sorteable, sort, onSort, onSelect, align = 'left', headerStyle, colStyle, filter} : TableHeaderCell) {
        this.label = label;
        this.key = key;
        this.sorteable = sorteable;
        this.sort = sort;
        this.onSort = onSort;
        this.onSelect = onSelect;
        this.align = align;
        this.headerStyle = headerStyle ?? {};
        this.colStyle = colStyle ?? {};
        this.filter = filter;
    }
}

class TableDetailCell {
    data: any;
    customDefinitions?: Map<string, ReactNode>;
    actions?: TableAction[];

    constructor(data: any, actions?: TableAction[], customDefinitions?: Map<string, ReactNode>) {
        this.data = data;
        this.actions = actions;
        this.customDefinitions = customDefinitions;
    }
}
enum FilterTableType {
    TEXT, COMBO, MULTIPLE
}


class FilterTable {
    type?: FilterTableType;
    placeholder?: string;
    data?: CodeDescription[];
    useCodeField?: boolean;
    onChange: (newVal: any)=>void;

    constructor({type = FilterTableType.TEXT, placeholder = '', data, onChange, useCodeField = false} : FilterTable) {
        this.type = type;
        this.placeholder = placeholder;
        this.data = data;
        this.useCodeField = useCodeField;
        this.onChange = onChange;
    }

}

class CodeDescription {
    id!: number;
    code?: string;
    description?: string;

    constructor(id?: number, code?: string, description?: string) {
        this.id = id ?? this.id;
        this.code = code ?? this.code;
        this.description = description ?? this.description;
    }
}

class DocumentType extends CodeDescription { 
}

class Channel extends CodeDescription{
}

class Company {
    id!: number;
    name?: string;
    taxNumber?: string;
    legalName?: string;
    code?: string;
    constructor( id?: number, name?: string, taxNumber?: string, legalName?: string, code?: string,) {
        this.id = id ?? this.id;
        this.name = name ?? this.name;
        this.taxNumber = taxNumber ?? this.taxNumber;
        this.legalName = legalName ?? this.legalName;
        this.code = code ?? this.code;
    }
}

class Template{
    id!: number;
    description?: string;
    name?: string;
    constructor( id?: number, name?: string, description?: string) {
        this.id = id ?? this.id;
        this.description = description ?? this.description;
        this.name = name ?? this.name;
    }
}

class ConfigTemplate{
    id!: number;
    name: string;
    template: Template;
    companies: Company[];
    channels: Channel[];
    documentTypes: DocumentType[];
    constructor( id: number, name: string, template: Template, companies: Company[], channels: Channel[], documentTypes: DocumentType[]) {
        this.id = id ?? this.id;
        this.name = name;
        this.template = template;
        this.companies = companies;
        this.channels = channels;
        this.documentTypes = documentTypes;
    }
}


class Receipt {
    id!: number;
    number?: string;
    order?: string;
    issueDate?: string;
    taxNumberRecipient?: number;
    dueDate?: string;
    fromDate?: string;
    toDate?: string;
    nameRecipient?: string;
    amountWithoutTax?: number;
    amountWithTax?: number;
    currency?: string;
    url?: string;
    storagePlace?: string;
    deliveryTracking?: string;
    statusDelivery?: string;
    ebsOrderNumber?: string;
    externalOrderNumber?: string;
    storeCode?: string;
    documentData?: any;
    documentType?: DocumentType;
    company?: Company;
    channel?: CodeDescription;
    business?: CodeDescription;
    sourceSystem?: CodeDescription;

    constructor({id= 0, number= undefined, order= undefined, issueDate= undefined, taxNumberRecipient= undefined, dueDate= undefined, fromDate= undefined, toDate= undefined, nameRecipient= undefined,
                amountWithoutTax= undefined, amountWithTax= undefined, currency= undefined, url= undefined, storagePlace= undefined,deliveryTracking= undefined, statusDelivery= undefined, ebsOrderNumber= undefined, externalOrderNumber= undefined, storeCode= undefined, documentData= undefined, documentType= undefined, 
                company= undefined, channel= undefined, business= undefined, sourceSystem = undefined}:Receipt) {
        this.id = id ?? this.id;
        this.number = number ?? this.number;
        this.order = order ?? this.order;
        this.issueDate = issueDate ?? this.issueDate;
        this.taxNumberRecipient = taxNumberRecipient ?? this.taxNumberRecipient;
        this.dueDate = dueDate ?? this.dueDate;
        this.fromDate = fromDate ?? this.fromDate;
        this.toDate = toDate ?? this.toDate;
        this.nameRecipient = nameRecipient ?? this.nameRecipient;
        this.amountWithoutTax = amountWithoutTax ?? this.amountWithoutTax;
        this.amountWithTax = amountWithTax ?? this.amountWithTax;
        this.currency = currency ?? this.currency;
        this.url = url ?? this.url;
        this.storagePlace = storagePlace ?? this.storagePlace;
        this.deliveryTracking = deliveryTracking ?? this.deliveryTracking;
        this.statusDelivery = statusDelivery ?? this.statusDelivery;
        this.ebsOrderNumber = ebsOrderNumber ?? this.ebsOrderNumber;
        this.externalOrderNumber = externalOrderNumber ?? this.externalOrderNumber;
        this.storeCode = storeCode ?? this.storeCode;
        this.documentData = documentData ?? this.documentData;
        this.documentType = documentType ?? this.documentType;
        this.company = company ?? this.company;
        this.channel = channel ?? this.channel;
        this.business = business ?? this.business;
        this.sourceSystem = sourceSystem ?? this.sourceSystem;
    }
}


export {
    FileResult,
    TableAction,
    TableHeaderCell,
    TableDetailCell,
    Receipt,
    DocumentType,
    Company,
    CodeDescription,
    FilterTableType,
    FilterTable,
    Template,
    ConfigTemplate,
    Channel
};