/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { SvgIcon, TableCell, Pagination, Select, MenuItem } from '@mui/material';
import './Datatable.css';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import {ReactComponent as CheckboxIcon} from '../../../assets/icons/checkbox.svg';
import { TableDetailCell, TableHeaderCell } from '../../../model/models-module';
import ActionMenu from './ActionsMenu';
import { extractHierachyValue } from '../../../utils/CommonUtils';
import FilterOption from './FilterOption';


type Props = {
  state?: string;
  className?: string;
  children?: React.ReactNode; 
  appendOnScrollToBottom?: boolean;
  selectable?: boolean;
  pageable?: boolean;
  pageSelected?: number;
  countItems?: number;
  rowsPerPage?: number; 
  headers: TableHeaderCell[];
  rows: TableDetailCell[];
  hasActions?: boolean;
  onPageSizeChange?(pageSize: number): void;
  onPageChange?(page: number): void;
  onScrollToBottom?(nextBlockSize: number): void; 
  onSortChange?(prop:string, orientation: string): void;
}

export default function DataTable({rows, headers, selectable = false, pageable = false, pageSelected=1, countItems = 0, rowsPerPage = 15,
    onSortChange, onPageChange, onPageSizeChange, appendOnScrollToBottom = false, onScrollToBottom, hasActions = false, className=''
}: Props) {
    // ref
    const tableRef = React.useRef<HTMLDivElement>(null);
    const [sort, setSort] = React.useState<number>(0);
    const [checkAll, setCheckAll] = React.useState<boolean>(false);
    const [dataChecked, setDataChecked] = React.useState<Map<number, any>>(new Map());// eslint-disable-line @typescript-eslint/no-explicit-any
    const [pageSize, setPageSize] = React.useState<number>(rowsPerPage);
    const [page, setPage] = React.useState<number>(pageSelected);
    const [newSort, setNewSort] = React.useState<Map<string, string>>(new Map());
    const [dataRows, setDataRows] = React.useState<TableDetailCell[]>(rows ?? []);


    const [pagesCount, setPagesCount] = React.useState<number>(0);

    const setNewOrder = (header:TableHeaderCell) => {

        switch(header.sort) {
            case '': 
                header.sort = 'ASC';
            break;
            case 'ASC': 
                header.sort = 'DESC';
            break;
            case 'DESC': 
                header.sort = '';
            break;
            default: 
                header.sort = 'ASC';
            break;
        }

        setNewSort(new Map([[header.key,header.sort]]));
    }

    const addRemoveSelection = (row: any, idx: number)=>{// eslint-disable-line @typescript-eslint/no-explicit-any
        const mapAux = new Map();
        dataChecked.forEach((value, key)=> mapAux.set(key, value));

        if(dataChecked.has(idx)){
            mapAux.delete(idx);
        } else {
            mapAux.set(idx, row);
        }
        setDataChecked(mapAux);
    };

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        if(onPageChange) {
            onPageChange(value);
        }
    };

    const validateAndScroll = (el: HTMLElement)=> {

        if(el && (el.offsetHeight + el.scrollTop) >= el.scrollHeight) {
            if(dataRows.length < countItems) {
                const nextBlock = (dataRows.length + pageSize) < countItems ? (pageSize) : countItems - dataRows.length;
                onScrollToBottom!(nextBlock);
            }
        }
    }

    const onScrollEvent = (event: React.UIEvent<HTMLElement, UIEvent>) => {
        if(!appendOnScrollToBottom) {
            return;
        }
        const el : HTMLElement = event.target as HTMLElement;
        validateAndScroll(el);
    };

    React.useEffect(
        ()=>{
            const mapAux = new Map();
            if(checkAll) {
                dataRows.forEach((r, i)=>{
                    mapAux.set(i, r);
                });
            }
            setDataChecked(mapAux);
            
        }, 
        [ checkAll ]
    );

    React.useEffect(
        ()=>{
            if(onSortChange && sort > 0) {
                onSortChange!(newSort.keys().next().value, newSort.values().next().value);
            }
        }, 
        [ newSort, sort ]
    );

    React.useEffect(
        ()=>{
            if(tableRef != null && tableRef != undefined && dataRows.length > 0 && appendOnScrollToBottom) {
                setTimeout(()=>{
                    validateAndScroll(tableRef!.current!);
                }, 1);
            }
            
        }, 
        [ tableRef, dataRows ]
    );

    React.useEffect(
        ()=>{
            setPage(pageSelected);
            
        }, 
        [ pageSelected ]
    );

    React.useEffect(
        ()=>{
            setPagesCount(Math.ceil(countItems / pageSize));
            
        }, 
        [ pageSize, countItems ]
    );

    React.useEffect(
        ()=>{
            setDataRows(rows);
            
        }, 
        [ rows ]
    );



  return (
    <>
        <TableContainer component={Paper} className={appendOnScrollToBottom ? 'newsan-table nwsn-scroll scrolleable '+className : 'newsan-table '+className} onScroll={onScrollEvent} ref={tableRef}>
        <Table  sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
            <TableRow key="header">
                {selectable ? 
                <TableCell key='selectHeader' className='checkbox-datatable'>
                    <span>
                        <SvgIcon onClick={()=>setCheckAll(!checkAll)}
                        className={ checkAll ? ' checked' : ''} component={CheckboxIcon} viewBox="0 0 18 22" />
                    </span>
                </TableCell> : null
                }
            {headers.map((header: TableHeaderCell, index) => (
                <TableCell key={`${header.label}${index}`}  className={`text-${header.align ?? 'left'}`}>
                <span style={header.headerStyle} className='data-header'>
                    {
                        header.filter ?
                        <FilterOption 
                        onChange={(newVal: string)=>{ header.filter!.onChange(newVal);}}
                        data={header.filter.data}
                        placeholder={header.filter.placeholder}
                        type={header.filter.type}
                        useCodeField={header.filter.useCodeField}
                        />
                        : null
                    }
                    <span className={`header-title text-${header.align ?? 'left'}`}>
                        {header.label} 
                    </span>
                    {
                    header.sorteable ? 
                    <span key={header.key} onClick={()=>{ setNewOrder(header);  setSort(sort+1);}}>
                        <i  className={header.sort == 'ASC' ? 'sort-active' : ''}>
                            <ArrowDropUpOutlinedIcon></ArrowDropUpOutlinedIcon>
                        </i>
                        <i className={header.sort == 'DESC' ? 'sort-active' : ''}>
                            <ArrowDropDownOutlinedIcon ></ArrowDropDownOutlinedIcon>
                        </i>
                    </span>
                    : null
                    }
                </span>

                </TableCell>
            ))}
            {
                hasActions ?
                <TableCell key='actionsHeader'>
                    <span style={{minWidth: '20px'}}></span>
                </TableCell> : null
            }
            </TableRow>
            </TableHead>
            {
            dataRows ? 
            <TableBody>
            {dataRows.map((row, index) => (
                <TableRow key={`row${index}`}>

                    {selectable ? 
                        <TableCell key={`check${index}`} className='checkbox-datatable'>
                            <span>
                            <SvgIcon onClick={()=>addRemoveSelection(row, index)}
                            className={ dataChecked.has(index) ? 'checked' : ''} component={CheckboxIcon} viewBox="0 0 18 22" />
                            </span>
                        </TableCell> : null
                    }

                    {headers.map((header: TableHeaderCell) => (
                        <TableCell key={`row${header.key}${index}`}>
                            
                            <span style={header.colStyle ?? {}}>
                                {
                                    row.customDefinitions != undefined && row.customDefinitions.has(header.key) 
                                    ?
                                    <span className='custom-element'>
                                        {row.customDefinitions.get(header.key)}
                                    </span>

                                    :
                                    extractHierachyValue({data: row.data, props: header.key})
                                }
                            </span>
                        </TableCell>
                    ))}
                    
                        {
                            (row.actions != undefined && row.actions.length > 0) ?
                                <TableCell>
                                        {
                                        <ActionMenu actions = {row.actions} index={index} row={row}>
                                        </ActionMenu>
                                        }
                                </TableCell>        
                            : 
                                null
                        }
                </TableRow>
            ))}
            </TableBody>
            :null
            }
        </Table>
        </TableContainer>
        { pageable && !appendOnScrollToBottom ? 
        <div className='col-12' style={{position: 'relative', marginTop: '20px'}}>
            <div className='col-3' style={{display: 'inline-block', width: '25%'}}>
                <label htmlFor="select" className='lbl-rows-per-page'>Mostrar</label>
                <Select
                    labelId="select"
                    id="select"
                    className='select-container'
                    value={pageSize}
                    onChange={(v)=>{setPageSize(Number(v.target.value)); onPageSizeChange!(Number(v.target.value));}}>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                </Select>
            </div>
            <div className='col-4 items-showed-legend' style={{display: 'inline-block', width: '35%', textAlign: 'right'}}>
            {`Mostrando ${((page-1) * pageSize) + 1} a ${(page * pageSize) > countItems ? countItems : (page * pageSize)} entradas de un total de ${countItems}`}
            </div>
            <div className='col-5' style={{position: 'absolute', right: '0', top: '10px'}}>
                <Pagination className='custom-pagination' count={pagesCount} page={page} onChange={handleChange} ></Pagination> 
            </div>
        </div>
        : null}
    </>
  );
}