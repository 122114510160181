import React, { ReactElement } from 'react'

type Props = {
  icon: ReactElement;
  title: string;
  desc?: string | null;
}


const ReceiptDetailItem = ({icon, title, desc = 'Sin Información'} : Props ) => {


  return <div className='receipt-detail-item'>
          <span className='detail-attr-icon'>{icon}</span>
          <div  className='detail-attr-desc-container'>
            <span className='detail-attr-title'>{title}</span>
            <span className='detail-attr-desc'>{desc}</span>
          </div>
    </div>
}

export default ReceiptDetailItem
