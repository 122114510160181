import { Drawer, List, Stack, Toolbar } from '@mui/material'
import appRoutes from '../../routes/appRoutes'
import SidebarItem from './SidebarItem'
import SidebarItemCollapse from './SidebarItemCollapse'
import UserService from '../../services/UserService'
import { useState } from 'react'


const Sidebar = () => {
  const [openDrawer, setOpenDrawer] = useState(true);

  return (
    <Drawer
      variant='permanent'
      className={openDrawer ? 'newsan-sidebar' : 'newsan-sidebar small'}
    >
      <List disablePadding>
        <Toolbar sx={{ marginBottom: '20px', minHeight: '50px!important' }}>
          <Stack sx={{ width: '100%' }} direction='row' justifyContent='center'>
            <div onClick={()=>{ 
              setOpenDrawer(!openDrawer);
            }}
            className={openDrawer ? 'newsan-logo' : 'newsan-icon'}
            >
            </div>
          </Stack>
        </Toolbar>
        {appRoutes
          .filter((r) => (r.roles && UserService.hasRole(r.roles)) || !r.roles)
          .map((route, index) =>
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse item={route} key={index} drawerExpanded={openDrawer} />
              ) : (
                <SidebarItem item={route} key={index}  drawerExpanded={openDrawer}/>
              )
            ) : null,
          )}
      </List>
    </Drawer>
  )
}

export default Sidebar
