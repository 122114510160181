/* eslint-disable @typescript-eslint/no-explicit-any */
import { SvgIcon } from '@mui/material';
import {ReactComponent as Character} from '../../assets/images/charater-pic.svg';
import {ReactComponent as Plant} from '../../assets/images/plant-pic.svg';


class NewsanPics {
    public static  CHARACTER: any = <SvgIcon component={Character} viewBox="0 0 342 268" />; 
    public static  PLANT: any = <SvgIcon component={Plant} viewBox="0 0 114 223" />; 

    



}



export default NewsanPics;
