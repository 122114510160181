/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import Topbar from '../../components/common/topbar/Topbar'
import { Box, Card } from '@mui/material'
import './ReceiptDetail.css'
import NewsanIcons from '../../components/common/Icons'
import ReceiptDetailItem from './ReceiptDetailItem'
import { useNavigate, useParams } from 'react-router-dom'
import useData from '../../components/hooks/useData'
import { Receipt } from '../../model/models-module'
import moment from 'moment'
import { formatDate, openFile } from '../../utils/CommonUtils'
import NewsanPics from '../../components/common/Images'
import { Button } from '@mui/base'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'




const ReceiptDetail = () => {
  const {id} = useParams();

	const navigate = useNavigate();
  const endpoint = `/documents/${id}`;
  const [loading, error, data] = useData<any>(endpoint, 'GET');
  const [receipt, setReceipt] = useState<Receipt>();


  useEffect(()=>{
    if(!loading && data) {
      setReceipt(data.results);
      console.warn(receipt)
    }
  }, [loading, data])

  if (loading) {
    return <div>Loading..</div>
  }

  if (error) {
    return (
      <div>
        Error: <code>{error}</code>
      </div>
    )
  }


  return <>
    <Topbar title={'Detalle del comprobante'} >
      </Topbar>
      <Card className='detail-cotainer'>
        <Box className='receipt-detail-col'>
          <ReceiptDetailItem icon={NewsanIcons.PEOPLE} title='Cliente - Razón Social' desc={receipt?.nameRecipient}></ReceiptDetailItem>
          <ReceiptDetailItem icon={NewsanIcons.CUIT} title='Cliente - DNI, CUIT, CUIL' desc={receipt?.taxNumberRecipient?.toString()}></ReceiptDetailItem>
          <ReceiptDetailItem icon={NewsanIcons.STORE} title='Empresa' desc={receipt?.company?.name}></ReceiptDetailItem>
          <ReceiptDetailItem icon={NewsanIcons.CUIT} title='CUIT' desc={receipt?.company?.taxNumber}></ReceiptDetailItem>
          <ReceiptDetailItem icon={NewsanIcons.DOCUMENT_TYPE} title='Tipo de comprobante' desc={receipt?.documentType?.description}></ReceiptDetailItem>
          <ReceiptDetailItem icon={NewsanIcons.CALENDAR} title='Fecha de emisión' desc={formatDate(moment(receipt?.issueDate).add(1, 'day').toDate())}></ReceiptDetailItem>

        </Box>
        <Box className='receipt-detail-col'>

          <ReceiptDetailItem icon={NewsanIcons.RECEIPT_SEARCH} title='Número de comprobante' desc={receipt?.number}></ReceiptDetailItem>
          <ReceiptDetailItem icon={NewsanIcons.WALLET} title='Monto sin impuesto' desc={`$${receipt?.amountWithoutTax?.toLocaleString('de')}`}></ReceiptDetailItem>
          <ReceiptDetailItem icon={NewsanIcons.WALLET_ADD} title='Monto con impuesto' desc={`$${receipt?.amountWithTax?.toLocaleString('de')}`}></ReceiptDetailItem>
          <ReceiptDetailItem icon={NewsanIcons.CURRENCY} title='Moneda' desc={receipt?.currency}></ReceiptDetailItem>
          <ReceiptDetailItem icon={NewsanIcons.RECEIPT} title='No Pedido externo' desc={receipt?.externalOrderNumber}></ReceiptDetailItem>

        </Box>
        <Box className='receipt-detail-col'>

          <ReceiptDetailItem icon={NewsanIcons.RECEIPT} title='Nº Pedido EBS' desc={receipt?.ebsOrderNumber}></ReceiptDetailItem>
          {/* <ReceiptDetailItem icon={NewsanIcons.SHIPMENT} title='Estado de Envío' desc={receipt?.statusDelivery}></ReceiptDetailItem>
          <ReceiptDetailItem icon={NewsanIcons.TEMPLATE} title='Número de tracking' desc={receipt?.deliveryTracking}></ReceiptDetailItem> */}
          <ReceiptDetailItem icon={NewsanIcons.STORE_CODE} title='Catálogo' desc={receipt?.storeCode}></ReceiptDetailItem>

        </Box>
        <Box className='receipt-detail-buttons-container'>
          <Button className='receipt-detail-button button-back' onClick={()=>navigate(-1)} >Volver</Button>
          <Button className='receipt-detail-button button-download' onClick={() => openFile(receipt?.url)}> <FileDownloadOutlinedIcon></FileDownloadOutlinedIcon>  <span>Descargar comprobante</span></Button>

        </Box>
        <span className='plant-container'>
          {NewsanPics.PLANT}
        </span>
        <span className='character-container'>
          {NewsanPics.CHARACTER}
        </span>
      </Card>
    </>
}

export default ReceiptDetail
