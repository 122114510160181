/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosInstance } from 'axios';
import { toast } from 'react-toastify'
import AxiosInterceptor from './http/AxiosInterceptor';

export interface IErrorResponse {
  error: { message?: string }
}

export type FindAllType = {
  limit?: number
  offset?: number
  sort?: string
}

export default class BaseService {
    baseURL: string | undefined = process.env.REACT_APP_API_BASE_URL;
    client: AxiosInstance;

    constructor() {
        this.client = AxiosInterceptor.addInterceptor(process.env.REACT_APP_API_BASE_URL);
    }

  async baseFindAll({ limit = 10, offset, sort }: FindAllType, url: string) {
    try {
      let params = ''
      if (offset) {
        params += `&offset=${offset}`
      }
      if (sort! && sort != '') {
        params += `&sort=${sort}`
      }
      const { data: result } = await this.client({
        url: `${url}?limit=${limit}${params}`,
        method: 'GET',
      })
      if (result.data.errorCode && result.data.errorMsg) {
        throw new Error(result.data.errorMsg)
      }
      return result.data
    } catch (e) {
      console.error(e)
      throw e
    }
  }


     handleError(err: any): void {

            const error = err as Error | AxiosError<IErrorResponse>
            if (axios.isAxiosError(error)) {
            if (error.code === AxiosError.ERR_CANCELED) {
                return
            }
            console.error(error?.response?.data?.message || error.message);
            if(Array.isArray(error?.response?.data?.message)) {
                toast.error(error?.response?.data?.message[0], {
                    position: 'top-right',
                });

            } else {
                toast.error(error.message, {
                    position: 'top-right',
                });
            }
            
            } else {
            console.error(error.message);
            toast.error(error.message, {
                position: 'top-right',
            });
            }
     }

}