/* eslint-disable @typescript-eslint/no-explicit-any */
import { SvgIcon } from '@mui/material';
import {ReactComponent as StockIcon} from '../../assets/icons/stock-icon.svg';
import {ReactComponent as MarketIcon} from '../../assets/icons/market-icon.svg';
import {ReactComponent as UserIcon} from '../../assets/icons/users-icon.svg';
import {ReactComponent as SettingsIcon} from '../../assets/icons/settings-icon.svg';
import {ReactComponent as QuestionsIcon} from '../../assets/icons/questions-icon.svg';
import {ReactComponent as RefreshIcon} from '../../assets/icons/refresh-menu-icon.svg';
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete-menu-icon.svg';
import {ReactComponent as CloneIcon} from '../../assets/icons/clone-menu-icon.svg';
import {ReactComponent as PlusIcon} from '../../assets/icons/plus-menu-icon.svg';
import {ReactComponent as NotificationIcon} from '../../assets/icons/notification-icon.svg';
import {ReactComponent as AddFileIcon} from '../../assets/icons/add-file-icon.svg';
import {ReactComponent as CsvFileIcon} from '../../assets/icons/csv-icon.svg';
import {ReactComponent as CancelIcon} from '../../assets/icons/cancel-error-icon.svg';
import {ReactComponent as CheckIcon} from '../../assets/icons/check-icon.svg';
import {ReactComponent as WarningIcon} from '../../assets/icons/warning-icon.svg';
import {ReactComponent as InfoIcon} from '../../assets/icons/info-icon.svg';
import {ReactComponent as LockIcon} from '../../assets/icons/lock-icon.svg';
import {ReactComponent as StoreIcon} from '../../assets/icons/store-icon.svg';
import {ReactComponent as SearchIcon} from '../../assets/icons/search-icon.svg';
import {ReactComponent as AddStoreIcon} from '../../assets/icons/add-store-icon.svg';
import {ReactComponent as ArrowCollapseIcon} from '../../assets/icons/arrow-collapse-icon.svg';
import {ReactComponent as ArrowExpandIcon} from '../../assets/icons/arrow-expand-icon.svg';
import {ReactComponent as ReceiptSearchIcon} from '../../assets/icons/voucher-icon.svg';
import {ReactComponent as ShipmentIcon} from '../../assets/icons/shipment-icon.svg';
import {ReactComponent as TemplateIcon} from '../../assets/icons/template-icon.svg';
import {ReactComponent as CuitIcon} from '../../assets/icons/cuit-icon.svg';
import {ReactComponent as CalendarIcon} from '../../assets/icons/calendar-icon.svg';
import {ReactComponent as DocumentTypeIcon} from '../../assets/icons/document-type-icon.svg';
import {ReactComponent as PeopleIcon} from '../../assets/icons/people-icon.svg';
import {ReactComponent as PersonalDocumentIcon} from '../../assets/icons/personal-document-icon.svg';
import {ReactComponent as ReceiptIcon} from '../../assets/icons/receipt-icon.svg';
import {ReactComponent as StoreCodeIcon} from '../../assets/icons/store-code-icon.svg';
import {ReactComponent as WalletIcon} from '../../assets/icons/wallet-icon.svg';
import {ReactComponent as WalletAddIcon} from '../../assets/icons/wallet-add-icon.svg';
import {ReactComponent as CurrencyIcon} from '../../assets/icons/currency-icon.svg';
import {ReactComponent as FilterIcon} from '../../assets/icons/filter-icon.svg';


class NewsanIcons {
    public static  STOCK: any = <SvgIcon component={StockIcon} viewBox="0 0 18 18" />; 
    public static  MARKET: any = <SvgIcon component={MarketIcon} viewBox="0 0 20 20" />; 
    public static  USERS: any = <SvgIcon component={UserIcon} viewBox="0 0 20 20" />; 
    public static  SETTINGS: any = <SvgIcon component={SettingsIcon} viewBox="0 0 20 20" />; 
    public static  QUESTIONS: any = <SvgIcon component={QuestionsIcon} viewBox="0 0 20 20" />; 
    public static  REFRESH: any = <SvgIcon component={RefreshIcon} viewBox="0 0 20 20" />; 
    public static  DELETE: any = <SvgIcon component={DeleteIcon} viewBox="0 0 20 20" />; 
    public static  CLONE: any = <SvgIcon component={CloneIcon} viewBox="0 0 20 20" />; 
    public static  PLUS: any = <SvgIcon component={PlusIcon} viewBox="0 0 20 20" />; 
    public static  NOTIFICATION: any = <SvgIcon component={NotificationIcon} viewBox="0 0 18 22" />; 
    public static  ADD_FILE: any = <SvgIcon component={AddFileIcon} viewBox="0 0 74 87" />; 
    public static  CSV_FILE: any = <SvgIcon component={CsvFileIcon} viewBox="0 0 48 50" />; 
    public static  CANCEL_ERROR: any = <SvgIcon component={CancelIcon} viewBox="0 0 20 20" />; 
    public static  CHECK: any = <SvgIcon component={CheckIcon} viewBox="0 0 20 20" />; 
    public static  WARNING: any = <SvgIcon component={WarningIcon} viewBox="0 0 27 25" />; 
    public static  INFO: any = <SvgIcon component={InfoIcon} viewBox="0 0 14 14" />; 
    public static  LOCK: any = <SvgIcon component={LockIcon} viewBox="0 0 21 23" />; 
    public static  STORE: any = <SvgIcon component={StoreIcon} viewBox="0 0 21 21" />; 
    public static  SEARCH: any = <SvgIcon component={SearchIcon} viewBox="0 0 18 17" />; 
    public static  ADD_STORE: any = <SvgIcon component={AddStoreIcon} viewBox="0 0 34 34" />; 
    public static  ARROW_COLLAPSE: any = <SvgIcon component={ArrowCollapseIcon} viewBox="0 0 23 15" />; 
    public static  ARROW_EXPAND: any = <SvgIcon component={ArrowExpandIcon} viewBox="0 0 23 15" />; 
    public static  RECEIPT_SEARCH: any = <SvgIcon component={ReceiptSearchIcon} viewBox="0 0 20 20" />; 
    public static  SHIPMENT: any = <SvgIcon component={ShipmentIcon} viewBox="0 0 20 20" />; 
    public static  TEMPLATE: any = <SvgIcon component={TemplateIcon} viewBox="0 0 20 20" />; 
    public static  CUIT: any = <SvgIcon component={CuitIcon} viewBox="0 0 22 20" />; 
    public static  CALENDAR: any = <SvgIcon component={CalendarIcon} viewBox="0 0 20 20" />; 
    public static  DOCUMENT_TYPE: any = <SvgIcon component={DocumentTypeIcon} viewBox="0 0 20 20" />; 
    public static  PEOPLE: any = <SvgIcon component={PeopleIcon} viewBox="0 0 14 20" />; 
    public static  PERSONAL_DOCUMENT: any = <SvgIcon component={PersonalDocumentIcon} viewBox="0 0 20 20" />; 
    public static  RECEIPT: any = <SvgIcon component={ReceiptIcon} viewBox="0 0 18 20" />; 
    public static  STORE_CODE: any = <SvgIcon component={StoreCodeIcon} viewBox="0 0 18 20" />; 
    public static  WALLET: any = <SvgIcon component={WalletIcon} viewBox="0 0 22 20" />; 
    public static  WALLET_ADD: any = <SvgIcon component={WalletAddIcon} viewBox="0 0 22 20" />; 
    public static  CURRENCY: any = <SvgIcon component={CurrencyIcon} viewBox="0 0 20 20" />; 
    public static  FILTER: any = <SvgIcon component={FilterIcon} viewBox="0 0 12 14" />; 

    



}



export default NewsanIcons;
