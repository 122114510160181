import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import MainLayout from './components/layout/MainLayout'
import { routes } from './routes'
import RenderOnAuthenticated from './components/auth/RenderOnAuthenticated'

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <RenderOnAuthenticated>
        <Routes>
          <Route path='/' element={<MainLayout />}>
            {routes}
          </Route>
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      </RenderOnAuthenticated>
    </BrowserRouter>
  )
}

export default App
