/* eslint-disable @typescript-eslint/no-explicit-any */
import { DocumentByQueryFilter } from '../dto/document-find-by-query.dto';
import { formatParamDate } from '../utils/CommonUtils';
import BaseService from './BaseService';
export default class DocumentService extends BaseService {

  private static _instance: DocumentService;

  public static getInstance(): DocumentService {
    if (!this._instance) {
      this._instance = new this()
    }
    return this._instance
  }

  async findByQuery({ limit = 10, offset, pSortArg, pClient, pReceiptNumber, pOrderNumber, pStoreCode, pExternalOrderNumber, pTipoComp, pCompanyFilter, pAmountWithoutTaxFrom, pAmountWithoutTaxTo, pAmountWithTaxFrom, pAmountWithTaxTo, pIssueDateFrom, pIssueDateTo }: DocumentByQueryFilter) {
    try {
      let url = `/documents?limit=${limit}&offset=${offset}`

      const appendStringParam = (paramCode:string, value?: string) =>{
        let param: string = '';

        if(value) {
          param += `&${paramCode}=${value}`;
        }

        return param;
      }

      const appendNumberParam = (paramCode: string, value?: number) =>{
        let param: string = '';

        if(value && value > 0) {
          param += `&${paramCode}=${value}`;
        }

        return param;
      }

      const appendDateParam = (paramCode: string, value?: Date) =>{
        let param: string = '';
        if(value) {
          param += `&${paramCode}=${formatParamDate(value)}`;
        }

        return param;
      }

      url += appendStringParam('sort', pSortArg);
      url += appendStringParam('client', pClient);
      url += appendStringParam('number', pReceiptNumber);
      url += appendStringParam('ebsOrderNumber', pOrderNumber);
      url += appendStringParam('storeCode', pStoreCode);
      url += appendStringParam('externalOrderNumber', pExternalOrderNumber);
      url += appendNumberParam('documentType', pTipoComp);
      url += appendNumberParam('company', pCompanyFilter);
      url += appendNumberParam('amountWithoutTaxFrom', pAmountWithoutTaxFrom);
      url += appendNumberParam('amountWithoutTaxTo', pAmountWithoutTaxTo);
      url += appendNumberParam('amountWithTaxFrom', pAmountWithTaxFrom);
      url += appendNumberParam('amountWithTaxTo', pAmountWithTaxTo);
      url += appendDateParam('issueDateFrom', pIssueDateFrom);
      url += appendDateParam('issueDateTo', pIssueDateTo);
      
      
      const { data: result } = await this.client({
        url,
        method: 'GET',
      })

      return result

    } catch (err: any) {
      throw await this.handleError(err)
    }

  }

}
