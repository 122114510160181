import { ReactNode, useEffect, useState } from 'react'
import { AppBar, Button, Toolbar } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
// import MenuItem from '@mui/material/MenuItem'
import colorConfigs from '../../../configs/colorConfigs'
import sizeConfigs from '../../../configs/sizeConfigs'
import UserService from '../../../services/UserService'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
// import MenuIcon from '@mui/icons-material/Menu';
// import InputBase from '@mui/material/InputBase';
import NewsanIcons from '../Icons';
import { useNavigate } from 'react-router-dom'
// import NotificationCard from './NotificationCard'
// import { NotifDateFormatter } from '../../../utils/FormatterUtils'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

type Props = {
  state?: string;
  title?: string;
  subtitle?: string;
  backButton?: boolean;
  children?: ReactNode;
}

// type Notification = {
//     id: number;
//     title: string;
//     message: string;
//     status: string;
//     date: Date;
// }

// const dataNotif: Notification[] = [
//   {id: 1, title: 'Titulo de la noti aca', message: '¡Gracias por usar nuestra aplicación y seguir disfrutando de todas las funcionalidades que tenemos para ti!', status: 'alert', date: new Date(2023, 7, 20)},
//   {id: 2, title: 'Otro titulo mas aca que puede ir', message: '¡Hemos añadido una función de "info" para que nunca olvides tus tareas importantes!', status: 'info', date: new Date(2023, 7, 20)},
// ]

const userImage = 'https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg';
const userHasImage = false;

const Topbar = ({ title, subtitle, children, backButton = false}: Props) => {
  const [username] = useState<string>(UserService.getUsername());
  const [email] = useState<string>(UserService.getEmail());
	const navigate = useNavigate();
  // const [searchText] = useState<string>('');

  const [goBack, setGoBack] = useState<boolean>(false);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  // const [anchorElNotif, setAnchorElNotif] = useState<null | HTMLElement>(null)

  // const [notifications] = useState<Notification[]>(dataNotif);


  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = (setting: string) => {
    setAnchorElUser(null)
    switch (setting) {
      case 'Logout':
        UserService.doLogout()
        break
      default:
        break
    }
  }

  // const handleOpenNotifMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorElNotif(event.currentTarget)
  // }

  // const handleCloseNotifMenu = () => {
  //   setAnchorElNotif(null)
  // }

  useEffect(()=>{
    if(goBack) {
      navigate(-1);
    }

  }, [goBack]);

  return (
    <AppBar
      position='relative'
      sx={{
        // width: `calc(100% - ${sizeConfigs.sidebar.width})`,
        width: '100%',
        height: sizeConfigs.topbar.heigth,
        boxShadow: 'unset',
        backgroundColor: colorConfigs.mainBg,
        color: colorConfigs.topbar.color,
        paddingLeft: '0px'
      }}
    >
      <Toolbar className='newsan-toolbar' sx={
        {
          paddingLeft: '0px !important',
          paddingRigth: '0px !important' 
        }
      }>
        <Container disableGutters maxWidth='xl'>
          <Toolbar disableGutters>
            <Box sx={{ width: '55%', display: 'inline-block', paddingTop: '10px' }}>
              <div className='topbar-icon-section'>
                {
                backButton ?
                  <NavigateBeforeIcon fontSize='large' style={{cursor: 'pointer'}} onClick={()=>{setGoBack(true);}}></NavigateBeforeIcon>
                :
                  <NavigateNextIcon fontSize='large'></NavigateNextIcon>
                }
              </div>
              <div className='topbar-title-section'>
                <span className='topbar-title'>{title}</span>
                <span className='topbar-subtitle'>{subtitle}</span>
              </div>
            </Box>
            <Box sx={{ width: '45%', textAlign: 'right' }}>
              {children}
              {/* <Box className='search-container' 
                    component="form"
                    noValidate
                    autoComplete="off">

                  <IconButton sx={{ p: '10px' }} aria-label="menu" className='search-button'>
                    {NewsanIcons.SEARCH}
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 2, width: 'calc(100% - 100px)' }}
                    placeholder="Búscar"
                    inputProps={{ 'aria-label': 'buscar' }}
                  />
                  <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                    <MenuIcon />
                  </IconButton>

              </Box>
              <IconButton className='notification-container' onClick={handleOpenNotifMenu} sx={{ p: 0 }} >
                {NewsanIcons.NOTIFICATION}
              </IconButton> */}
              {username ? (
                <Toolbar style={ {
                paddingRight: '0px',
                display: 'inline-block',
                }}    
                >
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar style={{borderRadius: '10px'}} alt={username} src='/static/images/avatar/2.jpg' />
                  </IconButton>
                </Toolbar>
              ) : (
                <></>
              )}
              <Menu
                sx={{ mt: '45px' }}
                id='menu-appbar'
                className='menu-user'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <div className='user-info'>
                  <div className='profile-pic-container'>
                    <Avatar className='profile-pic' style={{borderRadius: '10px'}} alt={username} src={userHasImage ? userImage : '/static/images/avatar/2.jpg'} />
                  </div>
                  <div className='profile-detail-container'>
                    <span className='profile-detail-name'>{username}</span>
                    <span className='profile-detail-email'>{email}</span>
                    <span className='profile-detail-rol'>Rol:</span>

                  </div>
                </div>

                <div className='menu-user-buttons-container'>
                  <Button variant="contained" className='button-newsan-grey'>{NewsanIcons.LOCK} Cambiar Contraseña</Button>
                  <Button variant="contained" className='button-newsan-red' onClick={() => handleCloseUserMenu('Logout')}><PowerSettingsNewIcon></PowerSettingsNewIcon> Cerrar Sesión</Button>

                </div>
                {/* {settings.map((setting) => (
                  <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                    <Typography textAlign='center'>{setting}</Typography>
                  </MenuItem>
                ))} */}
              </Menu>
            </Box>

              {/* <Menu
                sx={{ mt: '45px' }}
                id='menu-notifications'
                className='menu-notification'
                anchorEl={anchorElNotif}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElNotif)}
                onClose={handleCloseNotifMenu}
              >

                {notifications.map((n)=>{
                  return (
                  <MenuItem key={n.id} onClick={() => handleCloseNotifMenu()}>
                    <NotificationCard title={n.title} date={NotifDateFormatter(n.date)} icon={n.status == 'alert' ? NewsanIcons.WARNING : NewsanIcons.INFO} message={n.message} key={`noticard${n.id}`}  ></NotificationCard>
                  </MenuItem>
                  );

                  // <MenuItem key={'Notif'} onClick={() => handleCloseNotifMenu()}>
                  //   <div className='col-notif col-icon'>
                  //     <span className='menu-notif-icon-container'>
                  //       {NewsanIcons.WARNING}
                  //     </span>
                  //   </div>
                  //   <div className='col-notif col-text'>
                  //     <span className='notif-title'>Titulo de la noti aca</span>
                  //     <span className='notif-detail'>¡Gracias por usar nuestra aplicación y seguir disfrutando de todas las funcionalidades que tenemos para ti!</span>
                  //   </div>
                  //   <div className='col-notif col-date'>
                  //     <span className='notif-date-info'>
                  //       Ago 23 <span onClick={(e)=>{ e.stopPropagation(); e.preventDefault(); alert('delete notificacion') }}>{NewsanIcons.DELETE}</span>
                  //     </span>
                  //   </div>
                  // </MenuItem>
                })}
              </Menu> */}
          </Toolbar>
        </Container>
      </Toolbar>
    </AppBar>
  )
}

export default Topbar
