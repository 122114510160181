import { RouteType } from './config'
import ReceiptView from '../pages/receipts/Receipt'
import ReceiptDetail from '../pages/receipt-detail/ReceiptDetail'
import NewsanIcons from '../components/common/Icons'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import Templates from '../pages/templates-list/Templates'
import MassiveDownload from '../pages/massive-download/MassiveDownload'
import { Navigate } from 'react-router-dom'

const appRoutes: RouteType[] = [
  {
    index: true,
    element: <Navigate to="/receipts" replace />,
    state: 'home',
    
  },
  {
    path: '/receipts',
    element: <ReceiptView />,
    state: 'Comprobantes',
    sidebarProps: {
      displayText: 'Comprobantes',
      icon: NewsanIcons.RECEIPT_SEARCH
    },
  },
  {
    index: false,
    path: '/receipts/:id',
    element: <ReceiptDetail />,
    state: 'stock.load.field',
  },
  {
    path: '/templates',
    element: <Templates />,
    state: 'templates',
    sidebarProps: {
      displayText: 'Plantillas',
      icon: NewsanIcons.TEMPLATE
    },
    roles: ['admin'],
  },
  /* {
    path: '/shipments',
    element: <ReceiptDetail />,
    state: 'shipments',
    sidebarProps: {
      displayText: 'Envíos',
      icon: NewsanIcons.SHIPMENT
    },
    roles: ['admin'],
  }, */
  {
    path: '/download',
    element: <MassiveDownload />,
    state: 'download',
    sidebarProps: {
      displayText: 'Descarga masiva',
      icon: <FileDownloadOutlinedIcon></FileDownloadOutlinedIcon>
    },
    roles: ['admin'],
  },
  // {
  //   path: '/example2',
  //   element: <Example2PageLayout />,
  //   state: 'example2',
  //   sidebarProps: {
  //     displayText: 'Example 2',
  //     icon: <DashboardOutlinedIcon />,
  //   },
  //   child: [
  //     {
  //       index: true,
  //       element: <Example2Index />,
  //       state: 'example2.index',
  //     },
  //     {
  //       path: '/example2/child',
  //       element: <Example2ChildPage />,
  //       state: 'example2.child',
  //       sidebarProps: {
  //         displayText: 'Child',
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: '/example3',
  //   element: <AdminRolePage />,
  //   state: 'example3',
  //   sidebarProps: {
  //     displayText: 'Example 3',
  //     icon: <LockIcon />,
  //   },
  //   roles: ['admin'],
  // },
]

export default appRoutes
