import { Menu } from '@mui/material';
import { useEffect, useState } from 'react';
import InputFilter from '../input-filter/InputFilter';
import './InputNumberRange.css'


type InputRangeVal = {
    from?: number;
    to?:number;
}

type InputRangeProps = {
    valueFrom: number;
    valueTo: number;
    onChange(newVal:InputRangeVal): void;
}


const InputNumberRange = ({ valueTo = 0, valueFrom = 0, onChange }:InputRangeProps)=> {
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [auxValueFrom, setAuxValueFrom] = useState<number>(valueFrom);
    const [auxValueTo, setAuxValueTo] = useState<number>(valueTo);
    const [value, setValue] = useState<string>('');

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    useEffect(()=>{
        setValue(`${auxValueFrom} - ${auxValueTo}`);
        onChange({from: auxValueFrom, to:auxValueTo});
    }, [auxValueFrom, auxValueTo]);

    return   <>
            <div className='input-range-container' onClick={handleOpenUserMenu}>
                {value}
            </div>

              <Menu
                sx={{ mt: '45px' }}
                id='menu-appbar'
                className='menu-range'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <div style={{display: 'inline-block', width: '42%'}}>
                    <span className='title-input-range'>Desde</span>
                    <InputFilter placeholder='' type='currency' value={auxValueFrom} onChange={(newVal)=>{ 
                        if(newVal) {
                            setAuxValueFrom(newVal ? Number(newVal) : auxValueFrom); 
                        } else {
                            setAuxValueFrom(0)
                        }
                        }}></InputFilter>
                </div>
                <div style={{display: 'inline-block', width: '15%', textAlign: 'center'}}>
                    -
                </div>
                <div style={{display: 'inline-block', width: '42%'}}>
                    <span className='title-input-range'>Hasta</span>
                    <InputFilter placeholder='' type='currency' value={auxValueTo} onChange={(newVal)=>{
                        if(newVal) {
                            setAuxValueTo(newVal ? Number(newVal) : auxValueTo); 
                        } else {
                            setAuxValueTo(0)}
                    }}></InputFilter>
                </div>

              </Menu>
    </>;
}


export default InputNumberRange;