/* eslint-disable @typescript-eslint/no-explicit-any */
import { Menu, } from '@mui/material';
import React, { useState } from 'react';
import NewsanIcons from '../Icons';
import InputFilter from '../input-filter/InputFilter';
import CheckboxListFilter, { CheckboxValueField } from '../checkbox-list-filter/CheckboxListFilter';
import { CodeDescription, FilterTable, FilterTableType } from '../../../model/models-module';

const drawFilter = (onChange: (newVal:any)=>void, useCodeField: boolean, data?: CodeDescription[], type?: FilterTableType, placeholder?: string) => {

    switch(type) {
        case FilterTableType.COMBO:
            return <CheckboxListFilter listOptions={data ?? []} setSelectedOptions= {onChange} multiple={false}  valueField={useCodeField ? CheckboxValueField.CODE : CheckboxValueField.ID}></CheckboxListFilter>;
        case FilterTableType.MULTIPLE:
            return <CheckboxListFilter listOptions={data ?? []} setSelectedOptions= {onChange}  valueField={useCodeField ? CheckboxValueField.CODE : CheckboxValueField.ID}></CheckboxListFilter>;
        case FilterTableType.TEXT:
            return <span className='custom-element filter-option-text'> <InputFilter type='text' onChange={onChange} value={''} placeholder={placeholder}></InputFilter> </span> 
    }

}


const FilterOption = ({onChange, useCodeField = false, data, type, placeholder}: FilterTable) => {

    const [anchorElActions, setAnchorElActions] = useState<null | HTMLElement>(null);

    const handleOpenActionMenu = (event: React.MouseEvent<HTMLElement>) => {
        if (anchorElActions !== event.currentTarget) {
            setAnchorElActions(event.currentTarget);
        }
    }

    const handleCloseActionMenu = () => {
        setAnchorElActions(null);
    }

    return (
        <>
                <i className='filter-container' onClick={handleOpenActionMenu}>
                    {NewsanIcons.FILTER}
                </i>

                <Menu
                    sx={{ mt: '25px' }}
                    id={'menu-appbar'}
                    className='filter-options'
                    anchorEl={anchorElActions}
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    }}
                    open={Boolean(anchorElActions)}
                    onClose={handleCloseActionMenu}
                >
                    {drawFilter(onChange, useCodeField,  data, type, placeholder)}
                </Menu>
        </>

    )
};


export default FilterOption;