/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import axios from 'axios'
import 'moment/locale/es';

moment.locale('de');

const NotifDateFormatter = (date: Date) => {
    const aux = moment(date).format('DD MMM');
    let dateFormatted = aux.replace(/\d{2}\s(\w).*/g, '$1').toLocaleUpperCase();
    dateFormatted = aux.replace(/(\d{2}\s)\w.*/g, '$1') + dateFormatted + aux.replace(/\d{2}\s\w(.*)/g, '$1');
    return dateFormatted;
}


const formatParamDate = (date?: Date) => {
    if(date) {
        const aux = moment(date).format('yyyy-MM-DD');
        return aux;
    }
    return null;
}

const formatDate = (date?: Date) => {
    if(date) {
        const aux = moment(date).format('DD/MM/yyyy');
        return aux;
    }
    return null;
}

const extractHierachyValue = ({data, props}: {data:any, props: string}):any => {
    let result = null;
    if(props !== undefined && props !== null) {
        if(props.includes('.')) {
            const dotIndex = props.indexOf('.');
            result = data[props.substring(0, dotIndex)];
            return extractHierachyValue({data: result, props: props.substring(dotIndex+1)});
        } else {
            result = data ? data[props] : null;
        }
    }

    return result;

}

const openFile = async (url?: string) => {
    if (url) {
        try {
          const response = await axios.get(url, { responseType: 'blob' });
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const blobURL = window.URL.createObjectURL(blob);
          window.open(blobURL, '_blank');
        } catch (error) {
          console.error('Error al abrir el PDF:', error);
        }
      }
}


export {
    NotifDateFormatter,
    extractHierachyValue,
    formatDate,
    formatParamDate,
    openFile
};