import { Button, Menu } from '@mui/material';
import { useEffect, useState } from 'react';
import InputFilter from '../input-filter/InputFilter';
import './InputDateRange.css'
import { formatDate } from '../../../utils/CommonUtils';
import moment from 'moment';


type InputRangeVal = {
    from?: Date;
    to?:Date;
}

type InputRangeProps = {
    valueFrom?: Date;
    valueTo?: Date;
    onChange(newVal:InputRangeVal): void;
}


const InputDateRange = ({ valueTo, valueFrom, onChange }:InputRangeProps)=> {
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [auxValueFrom, setAuxValueFrom] = useState<Date|undefined>(valueFrom);
    const [auxValueTo, setAuxValueTo] = useState<Date|undefined>(valueTo);
    const [value, setValue] = useState<string>('');
    const [updateView, setUpdateView] = useState<number>(1);



    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    useEffect(()=>{
        
        setValue(`${formatDate(auxValueFrom) ?? ''} - ${formatDate(auxValueTo) ?? ''}`);
        onChange({from: auxValueFrom, to:auxValueTo});
    }, [auxValueFrom, auxValueTo]);

    return   <>
            <div className='input-range-container' onClick={handleOpenUserMenu}>
                {value}
            </div>

              <Menu
                id='menu-appbar'
                className='menu-range-date'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {
                    updateView > 0 ?
                        <div className='input-date-container'>
                            <span className='title-input-range'>Desde</span>
                            <InputFilter placeholder='' type='date' value={auxValueFrom} 
                            onChange={(newVal)=>{
                                const d:Date|undefined = newVal ? moment(Date.parse(newVal ?? '')).toDate() : undefined;

                                if(d != undefined) {
                                    const startDate = moment(auxValueFrom);
                                    const timeEnd = moment(d);
                                    const diff = timeEnd.diff(startDate);
                                    const diffDuration = moment.duration(diff);
                                    if(diffDuration.days() > 0){
                                        setAuxValueFrom(d);
                                    }
                                } else {
                                    setAuxValueFrom(d);   
                                }
                            }
                            }
                            ></InputFilter>
                        </div>
                    :
                        null
                    
                }
                <div style={{display: 'inline-block', width: '15%', textAlign: 'center'}}>
                    -
                </div>
                        <div className='input-date-container'>
                            <span className='title-input-range'>Hasta</span>
                            <InputFilter placeholder='' type='date' value={auxValueTo} onChange={(newVal)=>{
                                const d:Date|undefined = newVal ? moment(Date.parse(newVal ?? '')).toDate() : undefined;

                                if(d != undefined) {
                                    const startDate = moment(auxValueTo);
                                    const timeEnd = moment(d);
                                    const diff = timeEnd.diff(startDate);
                                    const diffDuration = moment.duration(diff);
                                    if(diffDuration.days() > 0){
                                        setAuxValueTo(d);
                                    }
                                } else {
                                    setAuxValueTo(d);   
                                }

                                }}></InputFilter>
                        </div>
                <Button className='button-calendar' onClick={()=>{
                    const to = new Date();
                    const from = moment(to).add(-6, 'day').toDate();
                    setAuxValueFrom(from);
                    setAuxValueTo(to);
                    setUpdateView(updateView+1)
                }}>última semana</Button>
                <Button className='button-calendar' onClick={()=>{
                    const to = moment().toDate();
                    const from = moment().add(-1, 'month').toDate();
                    setAuxValueFrom(from);
                    setAuxValueTo(to);
                    setUpdateView(updateView+1)

                }}>último mes</Button>
                <Button className='button-calendar' onClick={()=>{
                    const to = new Date();
                    const from = moment(to).add(-1, 'year').toDate();
                    setAuxValueFrom(from);
                    setAuxValueTo(to);
                    setUpdateView(updateView+1)

                }}>último año</Button>

              </Menu>
    </>;
}


export default InputDateRange;